@import '../../../theme/colors.scss';
@import 'theme/breakpoints.scss';

.WhoWeAreWrapper {
  background-color: $color-white;
  font-family: 'Univers';
  padding-bottom: 280px;
  @media (max-width: $lg) {
    padding-bottom: 460px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 490px;
  }
  .WhoWeAreHero {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      to bottom,
      $color-background 0%,
      $color-background 60%,
      $color-white 60%,
      $color-white 100%
    );
    @media (max-width: $lg) {
      column-gap: 5%;
    }
    @media (max-width: $sm) {
      background: linear-gradient(
        to bottom,
        $color-background 0%,
        $color-background 70%,
        $color-white 70%,
        $color-white 100%
      );
    }
    .WhoWeAreHeroInfo {
      display: flex;
      justify-content: space-between;
      column-gap: 5%;
      padding: 0 116px;
      margin-top: 20px;
      @media (max-width: $xxl) {
        padding: 0 58px;
      }
      @media (max-width: $md) {
        padding: 0 29px;
        margin-top: 0;
      }
      .WhoWeAreHeroVedio {
        width: 60%;
        iframe {
          @media (max-width: $lg) {
            height: 350px;
          }
          @media (max-width: $md) {
            height: 300px;
          }
          @media (max-width: $xs) {
            height: 220px;
          }
        }
      }
      .heroInfo {
        width: 40%;
        .heroInfoIcon {
          img {
            width: 55px;
            @media (max-width: $sm) {
              width: 45px;
            }
            &:lang(ar) {
              transform: rotateY(180deg);
            }
          }
        }
        .heroInfoDesc1 {
          font-family: 'Calibri';
          font-size: 2.1875rem;
          font-weight: bold;
          margin-top: 40px;
          line-height: 1.20;
          color: $color-primary;
          &:lang(ar) {
            font-family: 'NotoSansArabic';
          }
          @media (max-width: $xxl) {
            font-size: 1.875rem;
          }
          @media (max-width: $md) {
            font-size: 1.375rem;
            line-height: normal;
          }
          @media (max-width: $xxs) {
            font-size: 1.125rem;
          }
        }
        .heroInfoDesc2 {
          font-family: 'Calibri';
          font-size: 1.25rem;
          font-weight: normal;
          line-height: 1.43;
          text-align: justify;
          @media (max-width: $md) {
            line-height: normal;
          }
          &:lang(ar) {
            font-family: 'NotoSansArabic';
          }
        }
      }
      @media (max-width: $lg) {
        flex-direction: column-reverse;
        row-gap: 30px;
        .heroInfo,
        .WhoWeAreHeroVedio {
          width: 100%;
        }
      }
    }
  }

  .WhoWeAreMissionVision {
    position: relative;
    margin-top: 40px;
    @media (max-width: $xxl) {
      margin-top: 30px;
    }
    .WhoWeAreMissionVisionBG {
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: $xxl) {
        img {
          height: 400px;
        }
      }
      @media (max-width: $xl) {
        img {
          height: 330px;
        }
      }
      @media (max-width: $md) {
        img {
          height: 630px;
          width: 100%;
        }
      }
      @media (max-width: $xs) {
        img {
          height: 575px;
          width: 100%;
        }
      }
    }
    .WhoWeAreMissionVisionContent {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      width: 100%;
      bottom: 0;
      top: -40px;
      padding: 250px 200px;
      @media (max-width: $xxl) {
        top: 20px;
        padding: 0 58px;
        column-gap: 30px;
      }
      @media (max-width: $lg) {
        padding: 0 29px;
        column-gap: 30px;
      }

      .WhoWeAreMission,
      .WhoWeAreVision {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        .WhoWeAreMissionDesc,
        .WhoWeAreVisionDesc {
          text-align: center;
          font-family: 'Calibri';
          color: $color-white;
          margin-top: 10px;
          line-height: 1.43;
          font-size: 1.3125rem;
          padding: 20px 100px;
          @media (max-width: $xxl) {
            padding: 15px 50px;
          }
          @media (max-width: $xl) {
            padding: 15px 0;
          }
          @media (max-width: $sm) {
            margin-top: 20px;
          }
          @media (max-width: $xs) {
            font-size: 0.875rem;
          }
          &:lang(ar) {
            font-family: 'NotoSansArabic';
          }
        }
      }
    }
  }

  .WhoWeAreValues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 116px;
    margin-top: 100px;
    @media (max-width: $xxl) {
      margin-top: 80px;
    }
    .WhoWeAreValuesContent {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 80px;
      column-gap: 12%;
      @media (max-width: $xxl) {
        column-gap: 9%;
      }
      .WhoWeAreValuesItem {
        padding: 20px 0 50px;
        white-space: nowrap;
        @media (max-width: $xxl) {
          padding: 0;
        }
        .valueIcon {
          img {
            max-width: 100%;
            @media (max-width: $xxl) {
              max-width: 65px;
            }
          }
        }
        p{
          font-size:medium;
        }
        .valueName {
          margin-top: 30px;
          font-size: 1.3125rem;
          font-weight: bold;
          @media (max-width: $xxl) {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  .WhoWeAreVillages {
    display: flex;
    column-gap: 8%;
    padding: 85px 116px;
    background: #f6f8f9;
    margin-top: 80px;
    @media (max-width: $xxl) {
      padding: 85px 58px;
    }
    @media (max-width: $md) {
      padding: 85px 29px;
    }
    .WhoWeAreVillagesInfo {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .WhoWeAreVillagesInfoDesc {
        margin-top: 30px;
        text-align: justify;
        @media (max-width: $md) {
          margin-top: 15px;
        }
        p {
          font-family: 'Calibri';
          line-height: 1.43;
          font-size: 1.3125rem;
          @media (max-width: $md) {
            &:lang(ar) {
              line-height: normal;
            }
          }
          &:lang(ar) {
            font-family: 'NotoSansArabic';
          }
        }
      }
    }
    .WhoWeAreVillagesImage {
      width: 30%;
      img {
        width: 100%;
      }
    }
  }

  .WhoWeAreHistory {
    padding: 0 5%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .WhoWeAreHistoryDesc {
      margin-top: 30px;
      @media (max-width: $md) {
        margin-top: 15px;
      }
      padding: 0 15%;
      p {
        font-family: 'Calibri';
        font-size: 1.3125rem;
        color: $color-primary;
        margin-bottom: 0;
        line-height: 1.43;
        &:lang(ar) {
          font-family: 'NotoSansArabic';
        }
      }
      @media (max-width: $xxl) {
        padding: 0 10%;
        p {
          font-size: 1.125rem;
        }
      }
      @media (max-width: $md) {
        p:lang(ar) {
          line-height: normal;
        }
      }
    }
  }

  .WhoWeAreSponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
    margin-top: 80px;
    .WhoWeAreSponsorImage {
      img {
        max-width: 100%;
      }
    }
    .WhoWeAreSponsorBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
    }
  }

  .WhoWeArePartners {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    .WhoWeArePartnersContent {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;
      row-gap: 30px;
      @media (max-width: $xxl) {
        padding: 0 2%;
      }
      @media (max-width: $lg) {
        padding: 0 20px;
      }
      @media (max-width: $md) {
        row-gap: 0;
        margin-top: -30px;
      }
      .WhoWeArePartnersItem {
        flex: 1 0 19%;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 180px;
          max-height: 180px;
          margin-top: 35px;
          margin-top: 35px;
          @media (max-width: $xxl) {
            max-width: 150px;
          }
          @media (max-width: $md) {
            max-width: 100px;
          }
        }
      }
    }
  }
}

@media (max-width: $md) {
  .WhoWeAreWrapper {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    .WhoWeAreHero {
      padding-top: 30px;
      line-height: normal;
    }

    .WhoWeAreMissionVision {
      .WhoWeAreMissionVisionContent {
        flex-direction: column;
        row-gap: 30px;
        padding: 64px 10%;

        .WhoWeAreMission,
        .WhoWeAreVision {
          width: 100%;
        }
      }
    }

    .WhoWeAreValues {
      padding: 0;
      .WhoWeAreValuesContent {
        flex-direction: column;
        padding: 30px 30px 0;
        row-gap: 40px;
        .WhoWeAreValuesItem {
          .valueName {
            margin-top: 10px;
          }
        }
      }
    }

    .WhoWeAreVillages {
      padding: 30px;
      flex-direction: column;
      .WhoWeAreVillagesInfo,
      .WhoWeAreVillagesImage {
        width: 100%;
      }
      .WhoWeAreVillagesImage {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        img {
          max-width: 75%;
        }
      }
    }

    .WhoWeAreHistory {
      padding: 0 30px;
      margin-top: 50px;
      .WhoWeAreHistoryDesc {
        padding: 0;
      }
    }

    .WhoWeAreSponsor {
      padding: 0 30px;
      margin-top: 40px;
    }

    .WhoWeArePartners {
      padding: 0 30px;
      margin-top: 30px;
      margin-bottom: -30px;
      .WhoWeArePartnersContent {
        .WhoWeArePartnersItem {
          flex: 1 0 100%;
        }
      }
    }
  }
}

.WhoWeAreWrapper:lang(ar) {
  font-family: 'NotoSansArabic';
}
