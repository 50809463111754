@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.lateDuesWrapper {
  margin-top: 100px;
  margin-bottom: 270px;
  padding-right: 116px;
  padding-left: 116px;
  font-family: 'Univers';
  @media (max-width: $xxl) {
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    margin-bottom: 470px;
  }
  @media (max-width: $md) {
    margin-top: 13px;
    padding-right: 29px;
    padding-left: 29px;
    margin-bottom: 430px;
  }
  @media (max-width: $sm) {
    margin-bottom: 415px;
  }
  @media (max-width: '390px') {
    margin-bottom: 435px;
  }
  @media (max-width: '372px') {
    margin-bottom: 455px;
  }
  @media (max-width: $xxs) {
    margin-bottom: 480px;
  }
  .lateDuesTitle {
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
    @media (max-width: $lg) {
      text-align: center;
    }
    @media (max-width: $md) {
      font-size: 1.25rem;
      line-height: 2.5;
    }
  }
  .lateDuesContentWrapper {
    margin-top: 36px;
    .lateDuesContent {
      overflow-y: scroll;
      height: 530px;
      padding: 0 15px 0 0;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-gray;
        border-radius: 50px;
      }
    }
    .lateDuesDataWrapperDanger {
      margin-bottom: 5px;
    }
    .lateDuesDataWrapper {
      display: flex;
      column-gap: 17px;
      align-items: center;
      margin: 0 0 20px;

      .lateDuesData {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.125rem;
        padding: 28px 45px 29.5px 47px;
        background-color: $color-white;
        border-radius: 20px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        .childPayDate,
        .childPrice,
        .childIdName {
          width: 33%;
          @media (max-width: $md) {
            width: auto;
          }
        }
        .childPayDate {
          display: flex;
          column-gap: 8px;
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.88;
          letter-spacing: 1.6px;
          color: $color-black;
          @media (max-width: $md) {
            font-size: 0.75rem;
            line-height: 2.5;
            letter-spacing: 0.6px;
          }
          &:lang(ar) {
            letter-spacing: normal;
          }
        }
        .childPrice {
          color: $color-primary-500;
          padding: 0 50px;
          @media (max-width: $md) {
            padding: 0;
          }
        }
        .childIdName {
          color: $color-primary;
          span {
            color: $color-secondary-500;
            padding: 0 8px;
          }
        }
        @media (max-width: $md) {
          font-size: 0.875rem;
          padding: 21px 22px 21px 23px;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 19px;
          .datePriceWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          .childIdName {
            color: $color-secondary-500;
            span {
              color: $color-primary;
              padding: 0 8px;
            }
          }
        }
      }
      .checkboxInput {
        cursor: pointer;
        width: 20px;
        height: 20px;
        accent-color: $color-primary;
        @media (max-width: $md) {
          width: 17px;
          height: 17px;
        }
      }
    }
    .lateDuesPay {
      border-radius: 20px;
      border: solid 1px $color-secondary-500;
      background-color: $color-white;
      padding: 50px 69.5px 12px 70.5px;
      .divider {
        margin: 31px 0;
      }
      .lateDuesTotal {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;

        @media (max-width: $lg) {
          margin-top: 19px;
        }

        .lateDuesTotalTitle,
        .lateDuesTotalValue {
          font-size: 1.125rem;
          font-weight: bold;
          color: $color-primary;

          @media (max-width: '1400px') {
            max-width: 130px;
          }
          @media (max-width: $lg) {
            font-size: 0.875rem;
            max-width: initial;
          }
          @media (max-width: '375px') {
            max-width: 100px;
          }
        }

        .lateDuesTotalValueDanger {
          color: $color-error;
        }
      }
      .payBtn {
        margin-top: 60px;
        display: flex;
        justify-content: center;
      }
      @media (max-width: '1400px') {
        padding: 50px 65.5px 12px 65.5px;
      }
      @media (max-width: '1300px') {
        padding: 50px 35.5px 12px 35.5px;
      }
      @media (max-width: $xl) {
        padding: 50px 40.5px 12px 40.5px;
      }
      @media (max-width: $lg) {
        border: none;
        padding: 50px 69.5px 12px 70.5px;
      }
      @media (max-width: $md) {
        .divider {
          margin: 19px 0;
        }
        .payBtn {
          margin-top: 21px;
        }
      }
    }

    @media (max-width: $lg) {
      margin-left: -30px;
      margin-right: -30px;
      margin-top: 0;
      .lateDuesContentRow {
        flex-direction: column-reverse;
        .lateDuesContent {
          margin-top: 50px;
          height: 450px;
          @media (max-width: $md) {
            height: 630px;
          }
        }
        .lateDuesPay {
          border-radius: 15px;
          padding: 28px 34px 1px 33px;
        }
      }
    }
  }
  &:lang(ar) {
    font-family: NotoSansArabic;
  }
}
