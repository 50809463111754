@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.primaryMark {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  background-color: $color-black;
  height: 31px;
  width: 121px;
  color: $color-white;
  font-size: 1rem;
  line-height: 3.13px;
  letter-spacing: normal;
  padding-top: 3px;
  margin-top: 5px;
  @media (max-width: $sm) {
    height: 27px;
    width: 105px;
    margin-bottom: 10px;
    font-size: 0.75rem;
  }
}
.hidePrimaryMark {
  visibility: hidden;
  @media (max-width: $sm) {
    margin-bottom: -8px;
  }
}

.selectCardPrimary {
  @media (max-width: $md) {
    margin-left: 20px;

    margin-top: -20px;
  }
}
.cardWrapper {
  background-color: $color-white;
  border-radius: 20px;
  padding-top: 25px;
  padding-bottom: 41px;
  padding-left: 70px;
  padding-right: 70px;
  margin-top: 20px;

  @media (max-width: '1300px') {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cardTitle {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 1.6px;
    color: $color-secondary-500;
    text-transform: uppercase;
    text-align: center;
  }
  .cardContent {
    padding-top: 47px;
    .card {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      .cardRow {
        display: flex;
        div {
          width: 100%;
          font-size: 1.125rem;
          font-weight: normal;
        }
        .rowHeader {
          color: $color-secondary-500;

          @media (max-width: '430px') {
            max-width: 105px;
          }
        }
        .rowContent {
          color: $color-primary;
        }
        .cardNumber {
          font-size: 1rem;
        }
      }
    }
  }
  .cardBtns {
    padding-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cardRemove {
      width: 100%;
      display: flex;
      justify-content: end;
      cursor: pointer;
      @media (max-width: $sm) {
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .hideCardRemove {
      visibility: hidden;
    }
  }

  @media (max-width: $md) {
    padding-top: 14px;
    padding-bottom: 16px;
    padding-left: 21px;
    padding-right: 21px;
    .cardTitle {
      font-size: 0.75rem;
      letter-spacing: 0.6px;
      color: $color-secondary-500;
    }
    .cardContent {
      padding-top: 32px;
      .card {
        row-gap: 10px;
        .cardRow {
          display: flex;
          div,
          .cardNumber {
            font-size: 0.875rem;
          }
        }
      }
    }
    .cardBtns {
      padding-top: 24px;
    }
  }

  &:lang(ar) {
    .cardTitle {
      letter-spacing: normal;
    }
  }
}
.cardWrapperBorder {
  border: solid 1px $color-silver;
  padding-bottom: 69px;
  .cardContent {
    .card {
      .cardRow {
        div {
          min-width: 196px;
          @media (max-width: $md) {
            min-width: 170px;
          }
          @media (max-width: $sm) {
            min-width: auto;
          }
        }
        @media (max-width: '340px') {
          div {
            font-size: 0.75rem;
          }
          .cardNumber {
            font-size: 10px;
          }
        }
      }
    }
  }
  @media (max-width: $md) {
    padding-bottom: 47px;
  }
}
.cardWrapperShadow {
  box-shadow: 0 0 10px 0 $color-cornflower-blue;
}
.primaryModalTitle {
  font-family: 'Univers';
  font-size: 2.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $color-primary;
  margin-top: 89px;
  @media (max-width: $xxl) {
    font-size: 2.0625rem;
    margin-top: 50px;
  }
  @media (max-width: $md) {
    font-size: 1.25rem;
    line-height: normal;
    margin-top: 35px;
    p {
      margin-bottom: 35px;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
    line-height: 1.55;
  }
}

.primaryModalBtn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
  @media (max-width: $md) {
    margin-bottom: 20px;
  }
}
