@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.singleChildWrapper {
  padding-left: 116px;
  padding-right: 116px;
  padding-bottom: 300px;
  background-color: $color-white;
  .singleChildHeader {
    display: flex;
    justify-content: space-between;
    font-family: 'Univers';
    background-color: $color-background;
    padding: 53px 33px 46px;
    margin-top: 165px;
    .childCodeName {
      display: flex;
      font-family: 'Univers Bold';
      column-gap: 10px;
      font-size: 2.5rem;
      font-weight: bold;
      color: $color-primary;
      .childCode {
        color: $color-secondary-500;
      }
    }
    .childSponsorNow {
      display: flex;
      align-items: center;
      column-gap: 45px;
      .childPay {
        font-size: 1.25rem;
        font-weight: bold;
        color: $color-primary;
      }
    }
    @media (max-width: $xxl) {
      margin-top: 150px;
      .childCodeName {
        font-size: 1.875rem;
      }
      .childSponsorNow {
        .childPay {
          font-size: 1.125rem;
        }
      }
      @media (max-width: $xl) {
        .childCodeName {
          font-size: 1.5625rem;
        }
        .childSponsorNow {
          column-gap: 10px;
        }
      }
      @media (max-width: $md) {
        padding: 30px 0 23px;
      }
    }
    @media (max-width: $xl) {
      margin-top: 140px;
    }
    @media (max-width: $lg) {
      margin-top: 150px;
    }
    @media (max-width: $md) {
      flex-direction: column;
      row-gap: 40px;
      margin-left: -29px;
      margin-right: -29px;
      margin-top: 0;
      .childCodeName {
        font-size: 1.25rem;
        padding-left: 30px;
        padding-right: 30px;
      }
      .childSponsorNow {
        flex-direction: column;
        row-gap: 13px;
        .childPay {
          font-size: 1rem;
        }
      }
    }
  }
  .singleChildInfo {
    display: flex;
    column-gap: 100px;
    padding-top: 54px;

    .childImage {
      width: 31%;
      display: flex;
      height: 511px;
      align-items: start;
      overflow: hidden;
      border-radius: 20px;
      position: relative;

      .childBadge {
        background-color: $color-secondary;
        position: absolute;
        transform: rotate(315deg);
        top: 34px;
        padding: 0px 77px;
        color: $color-white;
        left: -78px;
        font-size: 1.25rem;
        
        &:lang(ar) {
          transform: rotate(45deg);
          left: auto;
          right: -63px;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
      @media (max-width: $md) {
        height: 348px;
      }
    }
    .childInfoContent {
      width: 69%;
      display: flex;
      flex-direction: column;
      row-gap: 80px;
      .childInfo {
        .childInfoYear {
          font-family: 'Univers';
          position: relative;
          font-size: 1.25rem;
          font-weight: bold;
          color: $color-primary;
          line-height: 1.5;
          letter-spacing: normal;
          .qouteIcon {
            position: absolute;
            left: -30px;
            top: -25px;
            @media (max-width: $lg) {
              left: 0;
            }
            img {
              transform: rotateY(180deg);
              width: 50px;
            }

            &:lang(ar) {
              img {
                transform: rotateY(360deg);
              }
              left: auto;
              right: -30px;
              @media (max-width: $lg) {
                right: 0;
              }
            }
          }
          @media (max-width: $lg) {
            padding: 0 30px;
          }
        }
        .childInfoData {
          margin-top: 7px;
        }
      }
      .childFamily {
        .childFamilyDetails {
          display: flex;
          flex-direction: column;
          row-gap: 25px;
          margin-top: 10px;
          .childFamilyMember {
            display: flex;
            column-gap: 17px;
            font-family: 'Univers';
            font-size: 1.125rem;
            line-height: normal;
            letter-spacing: normal;
            color: $color-primary;
            span {
              color: $color-secondary-500;
              min-width: 165px;
              letter-spacing: -0.9px;
              @media (max-width: $xs) {
                min-width: 120px;
              }
            }
          }
        }
      }
      .childAdditionalInfo {
        .childInfoData {
          margin-top: 13px;
        }
      }
    }
    @media (max-width: $xxl) {
      column-gap: 75px;
    }
    @media (max-width: $xl) {
      column-gap: 30px;
    }
    @media (max-width: $lg) {
      flex-direction: column;
      row-gap: 50px;
      .childImage,
      .childInfoContent {
        width: 100%;
        row-gap: 32px;
      }
    }
    @media (max-width: $md) {
      .childImage,
      .childInfoContent {
        .childInfo {
          .childInfoYear {
            font-size: 1rem;
            line-height: 1.88;
          }
        }
        .childFamily {
          .childFamilyDetails {
            row-gap: 15px;
            margin-top: 15px;
            .childFamilyMember {
              column-gap: 12px;
              font-size: 0.875rem;
              span {
                letter-spacing: -0.7px;
              }
            }
          }
        }
      }
    }
  }
  .singleChildVillageInfo {
    margin-top: 50px;
    .childInfoData {
      margin-top: 10px;
      width: 65%;
      @media (max-width: $lg) {
        width: 100%;
        line-height: 1.67;
      }
    }
  }

  .singleChildRemember {
    font-family: 'Univers';
    position: relative;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-primary;
    padding: 44px 420px 0;
    @media (max-width: $xxl) {
      padding: 44px 160px 0;
    }
    @media (max-width: $lg) {
      padding: 44px 100px 0;
    }
    @media (max-width: $md) {
      padding: 44px 20px 0;
      font-size: 1.1875rem;
    }
    @media (max-width: '375px') {
      padding: 44px 0 0;
    }
    .qouteIcon {
      position: absolute;
      right: 400px;
      top: 5px;
      @media (max-width: $xxl) {
        right: 145px;
      }
      @media (max-width: $xl) {
        right: 125px;
      }
      @media (max-width: $lg) {
        right: 70px;
      }
      @media (max-width: $md) {
        right: -5px;
        top: 10px;
        img {
          width: 50px;
        }
      }
      @media (max-width: '375px') {
        right: -15px;
      }
      &:lang(ar) {
        img {
          transform: rotateY(180deg);
        }
        right: auto;
        left: 400px;
        top: 20px;
        @media (max-width: $xxl) {
          left: 145px;
        }
        @media (max-width: $xl) {
          left: 125px;
        }
        @media (max-width: $lg) {
          left: 70px;
        }
        @media (max-width: $md) {
          left: -5px;
          top: 25px;
          img {
            width: 50px;
          }
        }
        @media (max-width: '375px') {
          left: -15px;
        }
      }
    }
  }
  .childInfoData {
    width: 80%;
    font-family: 'Calibri';
    font-size: 1.3125rem;
    font-weight: normal;
    color: $color-primary;
    line-height: 1.43;
    letter-spacing: normal;
    @media (max-width: $lg) {
      width: 100%;
    }
  }
  .childTitle {
    font-size: 1rem;
    font-weight: bold;
    color: $color-black;
    text-transform: uppercase;
    font-family: 'Univers';
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    column-gap: 13px;
    p {
      margin: 0;
    }
    &:lang(ar) {
      letter-spacing: normal;
    }
  }
  @media (max-width: $xxl) {
    padding-left: 58px;
    padding-right: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 500px;
  }

  @media (max-width: $md) {
    padding-left: 29px;
    padding-right: 29px;
    margin: -30px;
    padding-bottom: 480px;

    .childInfoData {
      font-size: 1.125rem;
      line-height: 1.39;
    }
    .childTitle {
      font-size: 0.75rem;
      letter-spacing: 1.2px;
    }
  }
  @media (max-width: $xxs) {
    padding-bottom: 520px;
  }
  :lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
