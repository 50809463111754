@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.nextPayWrapper {
  .nextPayTitle {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    color: $color-primary;
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0px;
    }

    .nextPayTitleView {
      font-weight: normal;
      padding: 0px 10px;
      cursor: pointer;

      img {
        transform: rotate(180deg);
        width: 26px;
      }
    }

    @media (max-width: $md) {
      font-size: 1rem;
      text-align: center;
      line-height: 2.5;

      p {
        margin-bottom: 20px;
      }

      .nextPayTitleView {
        font-size: 0.875rem;
      }
    }

    &:lang(ar) {
      text-align: right;
    }
  }

  .errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 79px 0 14px;

    .errorImage {
      padding-bottom: 16px;
    }

    p {
      margin-bottom: 0;
    }

    .donateBtn {
      padding-top: 51px;
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}

.childIdName {
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  color: $color-primary;
  direction: ltr;
  text-align: center;

  span {
    font-family: 'Univers';
    color: $color-secondary-500;
    margin: 0px 12px;
  }

  @media (max-width: $md) {
    font-size: 0.75rem;
    text-align: left;
  }
}

.childPrice {
  color: #6bc4e8;
  font-weight: bold;
  @media (max-width: $md) {
    font-size: 0.875rem;
  }
}

.childPayDate {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.88;
  letter-spacing: 1.6px;
  color: $color-black;
  text-align: left;

  @media (max-width: $md) {
    font-size: 0.75rem;
  }

  &:lang(ar) {
    letter-spacing: normal;
  }
}

.childPayDate::before {
  content: '';
  width: 8px;
  height: 13px;
  background-color: #6bc4e8;
  display: inline-block;
  margin: 0px 10px;
}
