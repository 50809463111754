.uploaderWrapper {
  justify-content: center;
  display: flex;

  .uploader {
    position: relative;

    .uploaderImage {
      .uploaderImageDefault {
        cursor: pointer;
      }
    }
    .uploaderAdd,
    .uploaderRemove {
      cursor: pointer;
      position: absolute;
      right: -10px;
      bottom: 0;

      img {
        height: 40px;
      }
    }
    .uploaderRemove {
      right: -5px;
      bottom: 15px;
    }
  }
}
