@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-input-search {
  .ant-input-group-addon {
    display: none;
    @media (max-width: $lg) {
      display: table-cell;
      .ant-btn-primary:hover,
      .ant-btn-primary:focus {
        border-color: $color-primary;
        background: $color-primary;
      }
    }
    .ant-btn {
      .anticon {
        font-size: 1.5625rem;
        margin-top: 2px;
      }
    }
  }
}
.ant-input-search > .ant-input-group > .ant-input:first-child {
  border-radius: 10px !important;
  border: solid 1px #b0b8be;
  padding: 18px 22px;
  font-size: 1.25rem;
  font-weight: bold;
  padding-left: 50px;
  &:lang(ar){
    padding-left: auto;
    padding-right: 50px;
  }
  &:focus {
    box-shadow: none;
  }
  @media (max-width: $xl) {
    padding: 14px 19px;
  }
  @media (max-width: $sm) {
    padding: 13px 15px;
    font-size: 1rem;
  }
}

@media (max-width: $lg) {
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 10px 10px 0 !important;
    height: 63px !important;
    padding: 0 20px !important;
    @media (max-width: $sm) {
      height: 54px !important;
      padding: 0 15px !important;
    }
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:lang(ar) {
    border-radius: 10px 0 0 10px !important;
  }
  .ant-input-search > .ant-input-group > .ant-input:first-child {
    border-radius: 10px 0 0 10px !important;
  }
  .ant-input-search > .ant-input-group > .ant-input-rtl:first-child {
    border-radius: 0 10px 10px 0 !important;
  }
}
