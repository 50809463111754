@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-modal-wrap {
  display: flex;
  align-items: center;
}
.ant-modal {
  top: 0;
  .ant-modal-body {
    padding-top: 40px;
    padding-left: 110px;
    padding-right: 110px;
    @media (max-width: $md) {
      padding-top: 24px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
.ant-modal-content {
  border-radius: 20px;
  @media (max-width: '767px') {
    margin: auto 30px;
  }
}
.ant-modal-header {
  display: none;
  .ant-modal-title {
    display: none;
  }
}
.ant-modal-footer {
  display: none;
}

.ant-modal-close-x {
  padding: 20px 70px;
  @media (max-width: $md) {
    padding: 5px 0;
  }
}

.ant-modal-mask {
  background-color: rgba(3, 47, 97, 0.1);
  backdrop-filter: blur(6.5px);
}
