@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.paymentCardWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    
    .paymentCard {
        background-color: $color-white;
        border-radius: 10px;
        padding: 20px;

        .paymentCardData {
            display: flex;
            justify-content: space-between;
        }

        .paymentCardName {
            margin-top: 20px;
        }
    }
}