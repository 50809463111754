@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.completeProfileWrapper {
  font-family: 'Univers';
  padding-top: 81px;
  padding-right: 404px;
  padding-left: 404px;
  padding-bottom: 267px;
  column-gap: 41px;
  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 200px;
    padding-left: 200px;
  }
  @media (max-width: $xl) {
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 458px;
  }
  @media (max-width: '387px') {
    padding-bottom: 497px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }
  .completeProfileForm {
    .formWrapper {
      .form {
        padding: 46px 80px 24px;
        .formInputs {
          display: flex;
          flex-direction: column;
          row-gap: 36px;
        }

        .sendBtn {
          display: flex;
          justify-content: center;
        }
        @media (max-width: $xxl) {
          padding: 46px 30px 24px;
        }

        @media (max-width: $md) {
          padding: 24px 0 4px;
          .formInputs {
            row-gap: 6px;
          }
        }
      }
    }
  }

  @media (max-width: $lg) {
    .completeProfileForm {
      width: 100%;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
