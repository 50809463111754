@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.giftsWrapper {
  font-family: Univers;
  padding-top: 100px;
  padding-right: 116px;
  padding-left: 116px;
  padding-bottom: 310px;
  column-gap: 41px;

  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $lg) {
    padding-bottom: 490px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 44px;
    padding-right: 29px;
    padding-left: 29px;
  }

  @media (max-width: '632px') {
    padding-bottom: 517px;
  }

  @media (max-width: $xs) {
    padding-bottom: 500px;
  }

  @media (max-width: '406px') {
    padding-bottom: 520px;
  }

  @media (max-width: $xxs) {
    padding-bottom: 554px;
  }

  .gifts {
    .giftsHeader {
      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        color: $color-primary;

        @media (max-width: $md) {
          font-size: 1.25rem;
          text-align: center;
        }
      }
    }

    .giftsCards {
      margin-top: 40px;
      @media (max-width: $md) {
        margin-top: 58px;
      }
    }

    .giftsPagination {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      @media (max-width: $md) {
        margin-top: 81px;
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
