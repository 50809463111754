@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.sectionTitle {
  display: flex;
  .sectionIcon {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .sectionIconOne {
      margin: 5px 20px 0;
      @media (max-width: $xxl) {
        margin: 0 20px;
      }

      @media (max-width: $xs) {
        margin: 0 10px;
        img {
          max-width: 25px;
        }
      }
      &:lang(ar) {
        margin: 12px 10px 0;
        @media (max-width: $xxl) {
          margin: 0 10px;
        }
        @media (max-width: $sm) {
          margin: 0;
        }
      }
    }
  }
  .sectionInfo {
    .fill-white {
      color: $color-white;
    }

    .fill-primary {
      color: $color-primary;
    }
    .titleInfoText {
      display: flex;
      justify-content: center;
      span {
        font-family: 'Univers';
        font-weight: bold;
        font-size: 2.5rem;
        &:lang(ar) {
          font-family: 'NotoSansArabic';
        }
        @media (max-width: $xxl) {
          font-size: 2.1875rem;
        }
        @media (max-width: $xs) {
          font-size: 1.25rem;
        }
      }
    }
    .iconTwo {
      display: flex;
      justify-content: center;
      margin-top: -10px;
      img {
        max-width: 50%;
        &:lang(ar) {
          max-width: 90%;
          @media (max-width: $xs) {
            max-width: 70%;
          }
        }
      }
      @media (max-width: $md) {
        margin-top: 0;
        img {
          max-width: 100%;
        }
      }
      &:lang(ar) {
        margin-top: 0;
        img {
          transform: rotateY(180deg);
        }
      }
    }
  }
}
