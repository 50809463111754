@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.cardWrapper {
  font-family: Univers;

  .card {
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    .cardBadge {
      background-color: $color-secondary;
      position: absolute;
      transform: rotate(315deg);
      top: 34px;
      padding: 0px 77px;
      color: $color-white;
      left: -78px;
      font-size: 1.25rem;
      
      &:lang(ar) {
        transform: rotate(45deg);
        left: auto;
        right: -63px;
      }
    }

    .cardImage {
      display: flex;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        object-fit: cover;
      }
    }

    .cardInfo {
      background-color: $color-background;
      padding: 20px 20px;

      .cardInfoTitle {
        font-size: 1.25rem;
        font-weight: bold;
        color: $color-primary;
        display: inline-block;
        width: 300px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        @media (max-width: $xxl) {
          width: 220px;
        }
        @media (max-width: $sm) {
          font-size: 1.0625rem;
        }
      }

      .cardInfoSubTitle {
        color: $color-secondary-500;
        font-size: 1.125rem;
        line-height: 0;
        margin-bottom: 10px;
        @media (max-width: $sm) {
          font-size: 0.875rem;
        }
      }

      .cardInfoLink {
        margin-top: -5px;
        display: flex;
        justify-content: end;

        a {
          display: flex;
          column-gap: 10px;
          align-items: center;
          p {
            color: $color-primary-500;
            font-size: 1rem;
            margin: 0;
            letter-spacing: 1.6px;
            text-transform: uppercase;

            padding-top: 6px;

            @media (max-width: $sm) {
              font-size: 0.75rem;
            }
            &:lang(ar) {
              letter-spacing: 0;
              padding-top: 0;
            }
          }
          // &:lang(ar) {
          //   align-items: center;
          // }
        }
      }
    }

    .cardButton {
      button {
        width: 100%;
        background: $color-primary-500;
        font-size: 1.5625rem;
        font-weight: bold;
        color: $color-white;
        border: none;
        padding: 12px 0px 10px;
        cursor: pointer;
        @media (max-width: $xxl) {
          font-size: 1.25rem;
        }
        @media (max-width: $md) {
          font-size: 1.125rem;
        }
      }
    }

    .cardInfoWithSpace {
      margin-top: 10px;
    }
    .cardInfoWithoutSpace {
      margin-top: 0;
      background-color: $color-white;
      border-left: 1px solid $color-secondary-500;
      border-right: 1px solid $color-secondary-500;
      .cardInfoTitle {
        color: $color-black;
        font-weight: normal;
        font-size: 1.5625rem;
        @media (max-width: $sm) {
          font-size: 1.125rem;
        }
      }
    }
  }
}

html:lang(ar) {
  .cardInfoTitle,
  .cardInfoSubTitle,
  .cardButton button,
  .cardBadge,
  .cardWrapper {
    font-family: NotoSansArabic;
  }

  .cardInfoLink {
    a {
      p {
        margin: 0px !important;
      }
      img {
        transform: rotate(180deg);
      }
    }
  }
}
