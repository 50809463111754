@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.confirmationWrapper {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding-top: 107px;
  padding-bottom: 81px;
  font-family: 'Univers';
  letter-spacing: normal;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  color: $color-primary;
  margin: 40px 451px 0;
  margin-bottom: 350px;
  @media (max-width: $xxl) {
    margin: 40px 230px 0;
    margin-bottom: 332px;
  }
  @media (max-width: $xl) {
    margin: 40px 150px 0;
    margin-bottom: 332px;
  }
  @media (max-width: $lg) {
    margin: 40px 58px 0;
    margin-bottom: 500px;
  }
  @media (max-width: $md) {
    margin: 17px 0 0;
    margin-bottom: 450px;
    padding-top: 25px;
    padding-bottom: 37px;
  }
  @media (max-width: '390px') {
    margin-bottom: 500px;
  }

  p {
    margin-bottom: 0;
  }
  .confirmationIcon {
    @media (max-width: $md) {
      img {
        width: 72px;
        height: 72px;
      }
    }
  }
  .confirmationText {
    margin-top: 41px;
    padding: 0 100px;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.25;
    @media (max-width: $md) {
      font-size: 1.25rem;
      line-height: normal;
    }
    @media (max-width: $sm) {
      padding: 0 35px;
    }
    @media (max-width: '375px') {
      padding: 0 10px;
    }
  }

  .confirmationRedirect {
    margin-top: 31px;
    font-size: 1.125rem;
    color: $color-secondary-500;
    line-height: 2.14;
    letter-spacing: -0.7px;
    span {
      padding: 0 5px;
    }
    @media (max-width: $md) {
      font-size: 0.875rem;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';

    .successConfirmation {
      letter-spacing: normal;
    }
  }
}
