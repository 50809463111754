@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.carouselSingleSlide {
  display: flex !important;
  justify-content: center;
  padding: 0 20px;

  @media (max-width: $sm) {
    padding: 0 30px;
  }
}
.customCarouselSingleSlide {
  display: inline-block !important;
}

// Slider Dots
.ant-carousel .slick-dots {
  position: relative;
  bottom: 0px;
  margin-top: 35px;
  margin-bottom: 0;
  align-items: center;
  column-gap: 3px;
  @media (max-width: $md) {
    margin-top: 25px;
  }
  @media (max-width: $xs) {
    margin-top: 20px;
  }

  li {
    background-color: #e6007e;
    opacity: 0.5;
    width: 13px !important;
    height: 13px !important;
    border-radius: 50%;
    transition: none;
    button {
      background-color: transparent !important;
      height: 100%;
    }
    @media (max-width: $sm) {
      width: 10px !important;
      height: 10px !important;
    }
  }

  .slick-active {
    width: 20px !important;
    height: 20px !important;
    opacity: 1;
    @media (max-width: $sm) {
      width: 15px !important;
      height: 15px !important;
    }
  }
}
.ant-carousel .slick-slide:lang(ar) {
  direction: rtl !important;
}
