$color-background: #f6f8f9;

$color-primary: #032f61;
$color-primary-500: #6bc4e8;
$color-secondary: #e6007e;
$color-secondary-500: #97a3ae;
$color-cornflower-blue: #76cef2;

$color-success: #5c946e;
$color-error: #d7263d;

$color-black: #000000;

$color-gray: #4f4f53;
$color-light-gray: #f5f7f9;
$color-silver: #b0b8be;

$color-offwhite: #97a3ad;
$color-white: #ffffff;
