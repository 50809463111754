@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.errorWrapper {
  background-color: $color-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 137px;
  font-family: 'Univers';
  padding-bottom: 336px;
  @media (max-width: $lg) {
    padding-bottom: 540px;
    padding-left: 58px;
    padding-right: 58px;
  }
  @media (max-width: $md) {
    padding-bottom: 482px;
    padding-left: 29px;
    padding-right: 29px;
  }
  @media (max-width: '400px') {
    padding-bottom: 500px;
  }
  @media (max-width: '375px') {
    padding-bottom: 518px;
  }
  @media (max-width:$xxs ) {
    padding-bottom: 535px;
  }
  .error {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    @media (max-width: $md) {
      background-color: $color-white;
      padding: 43px 120px 30px;
      border-radius: 15px;
    }
    @media (max-width: $sm) {
      padding: 43px 30px 30px;
    }
    p {
      margin-bottom: 0;
    }
    .errorImage {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .errorTitle,
    .errorSubtitle1,
    .errorSubtitle2 {
      font-size: 2.5rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: $color-primary;
      margin-top: 46px;
    }
    .errorSubtitle1,
    .errorSubtitle2 {
      font-size: 1.875rem;
      font-weight: normal;
      line-height: 1.67;
    }
    .errorSubtitle1 {
      margin-top: 17px;
    }
    .errorSubtitle2 {
      margin-top: 57px;
      @media (max-width:$md) {
        margin-top: 20px;
      }
    }
    .errorLinksWrapper {
      display: flex;
      column-gap: 100px;
      justify-content: space-between;
      margin-top: 34px;
      @media (max-width: $xl) {
        column-gap: 50px;
      }
      @media (max-width: $lg) {
        column-gap: 25px;
      }
      .errorLink {
        font-size: 1.25rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $color-secondary;
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
  @media (max-width: $md) {
    padding-top: 47px;
    margin: -30px;
    .error {
      .errorImage {
        img {
          width: 72px;
        }
      }
      .errorTitle {
        font-size: 1.25rem;
        margin-top: 20px;
      }
      .errorSubtitle1,
      .errorSubtitle2 {
        font-size: 1rem;
      }
      .errorLinksWrapper {
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        .errorLink {
          font-size: 1rem;
        }
      }
    }
  }
}
