@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.textSection {
  font-family: 'Calibri';
  padding: 0 13%;
  margin-top: 70px;
  .textSectionText {
    margin-top: 90px;
    font-size: 2.1875rem;
    font-weight: bold;
    line-height: 1.14;
    color: $color-primary;
    @media (max-width: $xxl) {
      font-size: 1.875rem;
    }
    @media (max-width: $md) {
      font-size: 1.25rem;
      margin-top: 50px;
    }
  }
  .textSectionValue {
    font-size: 1.3125rem;
    font-weight: normal;
    line-height: 1.43;
    color: $color-primary;
    margin-top: 10px;
    @media (max-width: $xxl) {
      font-size: 1.125rem;
    }
    @media (max-width: $md) {
      font-size: 1.125rem;
    }
  }
}
@media (max-width: $md) {
  .textSection {
    padding: 0;
    margin-top: 35px;
  }
}

.textSection:lang(ar) {
  font-family: 'NotoSansArabic';
}
