@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.projectListWrapper {
  padding: 180px 66px 125px;
  background-color: $color-white;
  @media (max-width: $xxl) {
    padding: 50px 28px;
  }
  @media (max-width: $lg) {
    padding: 50px 18px;
  }
  @media (max-width: $md) {
    margin-right: -32px;
    margin-left: -32px;
    margin-bottom: -30px;
    padding: 50px 0px;
  }

  .projectList {
    .projectListCards {
      .projectCard {
        padding: 0 20px;
        @media (max-width: $xxl) {
          padding: 0 10px;
        }
      }
    }

    .projectListExplore {
      display: flex;
      justify-content: center;
      margin-top: 90px;
      @media (max-width: $xl) {
        margin-top: 40px;
      }
      @media (max-width: $md) {
        margin-top: 33px;
      }
    }
  }
}
