@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.communitySucess {
  color: $color-success !important;
    border: 2px solid $color-success !important;
}

.communityError {
  color: $color-error !important;
    border: 2px solid $color-error !important;
}

.communityNameSuccess {
  font-family: Univers;
  font-size: 1rem;
  margin-top: 15px;
  color: $color-success;
}

.communityNameError {
  font-family: Univers;
  font-size: 1rem;
  margin-top: 15px;
  color: $color-error;
}