@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.changePasswordWrapper {
  font-family: 'Univers';
  padding-top: 110px;
  padding-right: 403px;
  padding-left: 403px;
  padding-bottom: 257px;
  @media (max-width: $xxl) {
    padding-top: 100px;
    padding-right: 200px;
    padding-left: 200px;
  }
  @media (max-width: $xl) {
    padding-right: 90px;
    padding-left: 90px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 458px;
  }
  @media (max-width: '390px') {
    padding-bottom: 505px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }

  .formWrapper {
    .form {
      padding: 10px 80px 12px;
      .formInputs {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
      .error {
        color: $color-error;
        margin-top: -40px;
        margin-bottom: 60px;
      }
      .sendBtn {
        display: flex;
        justify-content: center;
      }
    }
    @media (max-width: $xxl) {
      .form {
        padding: 0 20px 16px;
      }
    }
    @media (max-width: $md) {
      .form {
        padding: 9px 0 15px;
        .formInputs {
          row-gap: 26px;
        }
      }
    }
    @media (max-width: $sm) {
      .form {
        .resetPasswordText {
          padding: 0;
          font-size: 1rem;
        }
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
