@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 79px 0 14px;
    .errorImage {
      padding-bottom: 16px;
      img {
        @media (max-width: $sm) {
          width: 60px;
        }
      }
    }
    p {
      margin-bottom: 0;
      text-align: center;
      @media (max-width: $md) {
        font-size: 1rem;
      }
    }
    .desc1 {
      font-size: 1.25rem;
      text-align: center;
      @media (max-width: $md) {
        font-size: 1rem;
        padding: 0px 28px;
      }
      margin-bottom: 0px;
    }
    .desc2 {
      font-size: 1.25rem;
      text-align: center;
      @media (max-width: $md) {
        font-size: 1rem;
        padding: 0px 28px;
      }
      margin-bottom: 10px;
    }
    .donateBtn {
      padding-top: 15px;
      @media (max-width: $md) {
        padding-top: 22.5px;
      }
    }
  }
