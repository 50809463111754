@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-table {
  background: transparent !important;
  font-family: 'Univers';

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
.ant-table table {
  border-spacing: 0 20px;
}
.ant-table-thead > tr > th,
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
  color: $color-white;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  background: $color-secondary-500;
  border-bottom: none;
  text-transform: uppercase;
  &::before {
    display: none;
  }
}
.myDonationTable .ant-table-tbody > tr > td {
  width: 33.33%;
}
.ant-table-tbody > tr > td {
  padding-top: 32px;
  padding-bottom: 28px;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  color: $color-primary;
  background: $color-white;
  border-bottom: none;
}
.paymentHistoryInput {
  .ant-table-tbody > tr > td {
    padding-top: 28px;
    padding-bottom: 23px;
    @media (max-width: $md) {
      padding-top: 6px;
      padding-bottom: 3px;
    }
  }
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: $color-white;
}

.ant-table-container table > thead > tr:first-child th:last-child,
.ant-table-container table > tbody td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  &:lang(ar) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}
.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table-container table > tbody td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  &:lang(ar) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.ant-table-cell {
  padding: 18px 17px !important;
}

.ant-table-placeholder {
  .ant-table-cell,
  .ant-table-cell:hover {
    background: transparent !important;
    background-color: transparent !important;
  }
}

.sponsorshipsTitle .ant-table-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sponsorshipsTitle .ant-table-cell .actions {
  margin: 0px 80px;
}

.dashboardSponsorships .ant-table-cell .actions {
  margin: 0px 30px;
}

.hideTableHeader .ant-table-thead {
  display: none;
}

.font-weight-normal .ant-table-tbody > tr > td {
  font-weight: normal !important;
}
