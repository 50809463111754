@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ctaButton {
  position: relative;

  .ctaButtonCounter {
    position: absolute;
    top: -15px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $color-secondary;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    padding-top: 10px;
    &:lang(ar) {
      padding-top: 5px;
      left: -10px;
      right: inherit;
    }
  }

  button {
    cursor: pointer;
    font-family: Univers;
    padding: 10px 15px;
    white-space: nowrap;
    border: none;
    border-radius: 10px;
    font-size: 1.125rem;
  }
  .disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .fill-secondary {
    border-radius: 8px;
    background-color: $color-secondary;
    color: $color-white;
  }

  .fill-primary {
    border-radius: 8px;
    background-color: $color-primary;
    color: $color-white;
  }

  .fill-primary-500 {
    border-radius: 8px;
    background-color: $color-primary-500;
    color: $color-white;
  }

  .fill-secondary-500 {
    border-radius: 8px;
    background-color: $color-secondary-500;
    color: $color-white;
  }

  .pill-primary {
    border: 1px solid $color-primary;
    border-radius: 22px;
    background-color: transparent;
    color: $color-primary;
    padding: 10px 30px;
  }

  .pill-primary-selected {
    border: 1px solid $color-primary;
    border-radius: 22px;
    background-color: $color-primary;
    color: $color-white;
    padding: 10px 30px;
  }

  .border-primary-500 {
    background-color: $color-white;
    border: solid 1px $color-primary-500;
    color: $color-primary-500;
  }

  .border-primary {
    background-color: transparent;
    border: solid 1px $color-primary;
    color: $color-primary;
  }

  p {
    margin-bottom: 0;
  }
}

html:lang(ar) {
  .ctaButton {
    button {
      font-family: NotoSansArabic;
    }
  }
}

.loadingIcon {
  margin-right: 28px !important;
  margin-left: 0px !important;

  &:lang(ar) {
    margin-right: 0px !important;
    margin-left: 28px !important;
  }
}
