@import 'theme/antd-custom-theme.css';
@import 'theme/typography.scss';
@import 'theme/global.scss';


@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

html {
  font-size: 1rem;
  color: $color-primary;
}

@media (max-width: 1240px) {
  html {
    font-size: 0.8rem;
  }
}
