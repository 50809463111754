@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.homePage {
  padding-bottom: 230px;
  @media (max-width: $xxl) {
    padding-bottom: 250px;
  }
  @media (max-width: $lg) {
    padding-bottom: 480px;
  }
  @media (max-width: $sm) {
    padding-bottom: 450px;
  }
  @media (max-width: '375px') {
    padding-bottom: 480px;
  }
}
