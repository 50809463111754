@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.RadioWrapperForm {
  .ant-radio-group-outline {
    display: flex;
    margin-top: 20px;

    label {
      width: 100%;
      column-gap: 20px;

      span {
        font-family: Univers;
        font-size: 1.25rem;
        color: $color-black;

        @media (max-width: $xs) {
          font-size: 1rem;
        }

        &:lang(ar) {
          font-family: 'NotoSansArabic';
        }
      }

      .ant-radio {
        .ant-radio-inner {
          width: 22px;
          height: 22px;

          &::after {
            transform: scale(0.8);
            background-color: $color-primary-500;
          }
        }
      }
    }
  }
}
.lateDues,
.RadioWrapperFilter {
  .ant-radio-group {
    display: flex;
    column-gap: 50px;

    @media (max-width: $xs) {
      column-gap: 40px;
    }
  }
  .ant-radio-wrapper {
    font-family: 'Univers';
    font-size: 1.25rem;
    font-weight: normal;
    color: $color-black !important;

    .ant-radio-inner {
      border-color: $color-secondary-500;
      width: 16px;
      height: 16px;

      &::after {
        background-color: $color-primary-500;
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: $color-primary-500;

      &::after {
        background-color: $color-primary-500;
      }
    }

    .ant-radio {
      color: $color-primary-500;
    }

    &:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $color-primary-500;

      &::after {
        background-color: $color-primary-500;
      }
    }

    @media (max-width: $md) {
      font-size: 0.875rem;
    }
  }
}

.lateDues .ant-radio-wrapper {
  font-size: 1rem;
  color: $color-gray !important;
  @media (max-width: $md) {
    font-size: 0.75rem;
  }
}
.RadioWrapperCards {
  .ant-radio-group-outline {
    .ant-radio-wrapper {
      margin-right: 0;
      &::after {
        display: none;
      }
    }
    @media (max-width: $sm) {
      label {
        flex-direction: column;
        // row-gap: 10px;
        .ant-radio {
          .ant-radio-inner {
            width: 16px;
            height: 16px;
            &::after {
              transform: scale(0.5);
            }
          }
        }
      }
    }
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-primary-500 !important;
}
