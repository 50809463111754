@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.resendConfirmationWrapper {
  font-family: 'Univers';
  padding-top: 81px;
  padding-right: 404px;
  padding-left: 404px;
  padding-bottom: 267px;
  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 200px;
    padding-left: 200px;
  }
  @media (max-width: $xl) {
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 458px;
  }
  @media (max-width: '375px') {
    padding-bottom: 497px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }

  .formWrapper {
    .form {
      padding: 0 80px 34px;
      margin-top: -22px;
      .errorMessage {
        margin-bottom: 0;
        margin-top: -30px;
        color: $color-error;
      }
      .resendConfirmationText {
        font-size: 2.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -1.52px;
        text-align: center;
        color: $color-primary;
        padding: 0 20px;
        margin-bottom: 38px;
        p {
          margin-bottom: 0;
        }
      }
      .sendBtn {
        display: flex;
        justify-content: center;
        margin-top: 42px;
      }
    }
    @media (max-width: $xxl) {
      .form {
        padding: 0 30px 34px;
      }
    }
    @media (max-width: $xl) {
      .form {
        .resendConfirmationText {
          padding: 0;
        }
      }
    }
    @media (max-width: $md) {
      .form {
        padding: 0 0 8px;
        .resendConfirmationText {
          margin-bottom: 22px;
          font-size: 1.25rem;
        }
        .sendBtn {
          margin-top: 2px;
        }
      }
    }
    @media (max-width: $sm) {
      .form {
        .resendConfirmationText {
          padding: 0;
          font-size: 1rem;
        }
      }
    }
  }

  .emailWrapper {
    padding: 99px 0 116px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .emailText {
      font-size: 1.25rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $color-primary;
      padding: 0 180px;
      p {
        margin-bottom: 0;
      }
    }
    .email {
      padding: 40px 0 45px;
      font-size: 1.5625rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $color-primary;
      p {
        margin-bottom: 0;
      }
    }
    @media (max-width: $xxl) {
      .emailText {
        padding: 0 20px;
      }
    }
    @media (max-width: $md) {
      padding: 16px 0 29px;
      .emailText {
        font-size: 1rem;
        @media (max-width: $sm) {
          padding: 0;
        }
      }
      .email {
        font-size: 1.125rem;
        padding: 15px 0 17px;
        @media (max-width: $xxs) {
          font-size: 1rem;
        }
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
    .formWrapper {
      .form {
        .resendConfirmationText {
          letter-spacing: normal;
        }
      }
    }
  }
}
