@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Univers';
  position: absolute;
  width: 100%;
  bottom: 0px;

  @media (max-width: $lg) {
    padding-top: 250px;
  }
  @media (max-width: $sm) {
    padding-top: 210px;
  }
  .footerContent {
    width: 88%;
    position: absolute;
    margin-top: -100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    padding: 25px 100px;
    border: solid 1px #b0b8be;
    background: $color-white;
    @media (max-width: $xxl) {
      margin-top: -85px;
      padding: 25px 60px;
    }
    @media (max-width: $xl) {
      padding: 20px 30px;
    }

    @media (max-width: $lg) {
      margin-top: -210px;
    }

    @media (max-width: $sm) {
      margin-top: -190px;
    }

    a {
      img {
        min-width: 133px;
        @media (max-width: $xxl) {
          min-width: 100%;
        }
      }
    }
    .footerContentMenuItems {
      display: flex;
      align-items: center;
      column-gap: 20px;
      row-gap: 10px;
      font-weight: bold;
      white-space: nowrap;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: $xxl) {
        column-gap: 10px;
      }
      @media (max-width: $xl) {
        column-gap: 1px;
      }

      a {
        font-size: 1.125rem;
        color: $color-primary;

        @media (max-width: $xxl) {
          font-size: 1rem;
        }
        @media (max-width: $md) {
          font-size: 13px;
        }

        @media (max-width: $sm) {
          font-size: 0.75rem;
        }
      }

      .antDivider {
        background-color: $color-primary;

        &:last-child {
          display: none;
        }
      }

      @media (max-width: $md) {
        column-gap: 10px;
      }

      @media (max-width: $sm) {
        column-gap: 0;
      }

      .menuItem {
        display: flex;
        align-items: center;
      }
    }

    .footerContactInfo {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      font-size: 1rem;
      font-weight: 300;

      @media (max-width: $xxl) {
        font-size: 0.875rem;
      }

      .footerContactInfoEmail,
      .footerContactInfoPhone {
        display: flex;
        align-items: center;
        column-gap: 20px;
        white-space: nowrap;
        a {
          color: $color-primary;
          direction: ltr;

          @media (max-width: $md) {
            font-size: 0.875rem;
          }
        }

        @media (max-width: $xs) {
          column-gap: 8px;
          a {
            font-size: 11px;
          }
        }

        @media (max-width: $xxs) {
          column-gap: 5px;
        }

        div {
          display: flex;
          column-gap: 20px;
          @media (max-width: $xl) {
            column-gap: 14px;
          }
          @media (max-width: $lg) {
            column-gap: 20px;
          }
          @media (max-width: $md) {
            column-gap: 17px;
          }
          @media (max-width: $sm) {
            column-gap: 18px;
          }
          @media (max-width: $xs) {
            column-gap: 10px;
          }
          @media (max-width: '390px') {
            column-gap: 4px;
          }

          .footerFlagImage {
            width: 30px;
          }

          .footerFlagPhone {
            direction: ltr;
          }
        }
      }
    }
  }

  .footerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 1rem;
    font-weight: normal;
    background: $color-primary;
    color: $color-white;
    padding: 50px 100px 8px;

    @media (max-width: $xxl) {
      padding: 50px 70px 8px;
      font-size: 0.875rem;
    }
    @media (max-width: $xl) {
      padding: 50px 50px 8px;
      font-size: 13px;
    }

    .footerInfoWrapper {
      display: flex;
      justify-content: space-between;
    }

    a {
      color: $color-white;
      cursor: pointer;
      font-size: 1rem;
    }

    .antDivider {
      background-color: $color-white;
    }

    .footerInfoMiddle {
      a {
        font-weight: bold;
      }
    }
  }
}

@media (max-width: $lg) {
  .footerWrapper {
    .footerContent {
      width: 70%;
      flex-direction: column;
      row-gap: 25px;
      padding: 20px 40px;

      .footerUpperLogo {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .footerContentMenuItems {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .footerContactInfo {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .footerInfo {
      padding: 74px 80px 20px;

      .footerInfoWrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
        row-gap: 15px;

        .footerInfoRight {
          order: 1;
        }

        .footerInfoLeft {
          order: 2;
          display: flex;
          flex-direction: column;
          row-gap: 15px;
        }

        .footerInfoMiddle {
          order: 3;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .footerWrapper {
    .footerContent {
      width: 82%;
      padding: 25px 20px 40px;

      @media (max-width: $xxs) {
        width: 90%;
      }
    }

    .footerInfo {
      padding: 100px 20px 20px;
      font-size: 11px;

      a {
        font-size: 0.75rem;
      }
    }
  }
}

html:lang(ar) {
  .footerWrapper {
    font-family: 'NotoSansArabic';
  }

  .footerContactInfoEmail,
  .footerContactInfoPhone {
    flex-direction: row-reverse;
    div {
      flex-direction: row-reverse;
    }
  }
}
