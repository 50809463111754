@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.myCardsWrapper {
  font-family: 'Univers';
  padding-top: 100px;
  padding-left: 116px;
  padding-right: 116px;
  padding-bottom: 272px;
  @media (max-width: $xxl) {
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $lg) {
    padding-top: 51px;
    padding-bottom: 470px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 44px;
    padding-right: 29px;
    padding-left: 29px;
    padding-bottom: 483px;
  }

  @media (max-width: '632px') {
    padding-bottom: 490px;
  }

  @media (max-width: $sm) {
    padding-bottom: 495px;
  }

  @media (max-width: '390px') {
    padding-bottom: 515px;
  }

  @media (max-width: $xxs) {
    padding-bottom: 547px;
  }
  .error {
    margin-top: -30px;
    margin-bottom: 55px;
    width: 32%;
    @media (max-width: $xl) {
      width: 100%;
    }
    @media (max-width: $xl) {
      margin-top: -23px;
      margin-bottom: 35px;
    }
  }
  .myCardsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 71px;

    .myCardTitle {
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: normal;
      color: $color-primary;
    }

    @media (max-width: $md) {
      flex-direction: column;
      row-gap: 35px;
      justify-content: center;
      align-items: center;
      margin-bottom: 57px;

      .myCardTitle {
        font-size: 1.25rem;
        line-height: 2.5;
        text-align: center;
      }
    }
  }
  .myCards {
    row-gap: 15px !important;
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
