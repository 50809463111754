@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.lateDuesWrapper {
  @media (max-width: $lg) {
    margin-top: 40px;
  }

  .lateDuesTitle {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    color: $color-primary;
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0px;
      .lateDuesTitleTotal {
        border-radius: 10px;
        background-color: $color-white;
        padding: 10px 15px;
        margin: 0px 10px;
        font-size: 1rem;
        line-height: 1.56;
        letter-spacing: 1.6px;
        color: #aeb8bf;
        font-weight: normal;
        text-transform: uppercase;

        @media (max-width: $md) {
          font-size: 0.75rem;
          margin: 0px 0px;
        }
        .lateDuesTotal {
          padding: 0 10px;
        }
        span {
          color: $color-error;
          font-size: 1.125rem;
          font-weight: bold;
          line-height: 1.67;

          @media (max-width: $md) {
            font-size: 0.875rem;
          }
        }

        &:lang(ar) {
          letter-spacing: normal;
        }
      }
    }

    .lateDuesTitleView {
      font-size: 1.125rem;
      font-weight: bold;
      color: $color-error;
      cursor: pointer;
      .lateDuesTitleTotalSettleAll {
        text-decoration: underline;
      }
    }

    @media (max-width: $md) {
      font-size: 1rem;
      line-height: 2.5;
      flex-direction: column;

      p {
        margin-bottom: 20px;
      }

      .lateDuesTitleView {
        font-size: 0.875rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &:lang(ar) {
      text-align: right;
    }
  }

  .errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 79px 0 14px;

    .errorImage {
      padding-bottom: 16px;
    }

    p {
      margin-bottom: 0;
    }

    .donateBtn {
      padding-top: 51px;
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}

.childIdName {
  font-family: 'Univers';
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  color: $color-primary;
  direction: ltr;

  text-align: left;
  span {
    color: $color-secondary-500;
    margin: 0px 12px;
  }

  @media (max-width: $md) {
    font-size: 0.75rem;
  }
}

.childPrice {
  color: #6bc4e8;
  font-weight: bold;
  padding: 0 50px;

  @media (max-width: $md) {
    padding: 0;
    font-size: 0.875rem;
  }
}

.childPayDate {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.88;
  letter-spacing: 1.6px;
  color: $color-black;
  text-align: start;

  @media (max-width: $md) {
    font-size: 0.75rem;
  }
}

.childPayDate::before {
  content: '';
  width: 8px;
  height: 13px;
  background-color: #6bc4e8;
  display: inline-block;
  margin: 0px 10px;
}
