@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.cartSummary {

    .cartSummaryItems {
        margin-bottom: 33px;
        
        @media (max-width: $lg) {
            margin-bottom: 17px;
        }
        .itemRow {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            
            @media (max-width: $lg) {
                margin-top: 16;
            }
            
            .itemRowName {
                font-size: 1.25rem;
                line-height: 1.67;
                color: $color-primary;

                @media (max-width: $lg) {
                    font-size: 0.875rem;
                }
            }
            
            .itemRowInfo {
                .itemRowInfoPrice {
                    font-size: 1.25rem;
                    line-height: 1.67;
                    color: $color-primary;

                    @media (max-width: $lg) {
                        font-size: 0.875rem;
                    }
                }
            }
        }
        .itemRowInfoSubName {
            border-bottom: 1px solid rgba($color: $color-secondary-500, $alpha: 0.3);
            font-size: 0.875rem;
            color: $color-gray;
            display: flex;
            justify-content: end;
            padding-bottom: 10px;

            @media (max-width: $lg) {
                font-size: 10px;
            }

            span {
                margin-left: 3px;
                &:lang(ar) {
                    margin-left: 0px;
                    margin-right: 3px;

                }
            }
        }
        
        .itemRowInfoSubName:last-of-type {
            border-bottom: 1px solid rgba($color: $color-secondary-500, $alpha: 1);
        }
    }

    .cartSummaryTotal {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;
        
        @media (max-width: $lg) {
            margin-top: 12px;
        }

        .cartSummaryTotalTitle,
        .cartSummaryTotalValue {
            font-size: 1.25rem;
            font-weight: bold;
            color: $color-primary;

            @media (max-width: $lg) {
                font-size: 0.875rem;
            }
        }

        .cartSummaryTotalValueDanger {
            color: $color-error;
        }
    }
}