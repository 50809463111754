@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.formWrapper {
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(3, 47, 97, 0.16);
  border: solid 1px $color-secondary-500;
  background-color: $color-white;
  .formTitle {
    display: flex;
    justify-content: center;
    // align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .backIcon {
      cursor: pointer;
      img {
        width: 65px;
        height: 31px;
        @media (max-width: $md) {
          width: 35px;
          height: 18px;
        }
        @media (max-width: $xs) {
          width: 25px;
          height: 15px;
        }
        @media (max-width: $xxs) {
          width: 23px;
          height: 12px;
        }
        &:lang(ar) {
          transform: rotateY(180deg);
        }
      }
    }
    p {
      color: $color-primary;
      margin-bottom: 0;
      font-weight: bold;
      @media (max-width: $xxl) {
        font-size: 1.75rem;
      }
      @media (max-width: $md) {
        font-size: 1.1875rem;
      }
    }
  }
  .form {
    padding: 0 75px;
    @media (max-width: $xl) {
      padding: 0 50px;
    }
    @media (max-width: $lg) {
      padding: 0 60px;
    }
    @media (max-width: $md) {
      padding: 0 30px;
    }
  }
}
.full-background-color {
  background-color: $color-background;
  padding: 33px 70px;
  p {
    font-size: 1.875rem;
  }
  @media (max-width: $md) {
    padding: 25px 27px;
  }
}
.full-white-color {
  background-color: $color-white;
  padding: 60px 156px;
  p {
    font-size: 2.5rem;
  }
  @media (max-width: $xxl) {
    padding:60px 70px;
  }
  @media (max-width: $md) {
    padding: 30px;
  }
}
