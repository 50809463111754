@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.myDonationsWrapper {
  @media (max-width: $lg) {
    margin-top: 40px;
  }
  .myDonationsTitle {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    color: $color-primary;
    display: flex;
    justify-content: space-between;

    @media (max-width: $md) {
      font-size: 1rem;
    }
    p {
      margin-bottom: 0px;
    }
    .myDonationsTitleView {
      font-weight: normal;
      padding: 0px 10px;
      cursor: pointer;

      img {
        width: 26px;
        margin-left: 9px;
        &:lang(ar) {
          transform: rotate(180deg);
          margin-left: 0px;
          margin-right: 9px;
        }
      }
    }
    @media (max-width: $md) {
      font-size: 1rem;
      text-align: center;
      line-height: 2.5;
      .myDonationsTitleView {
        font-size: 0.875rem;
      }
    }
    &:lang(ar) {
      text-align: right;
    }
  }
  .purposeWrapper {
    display: flex;
    column-gap: 18px;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 13px 0;
    .purpose {
      .purposeIcon {
        max-width: 30px;
      }
      .purposeText {
        text-align: left;
        font-size: 1rem;
      }
    }

    .purposeValue {
      text-align: start;
    }

    div:first-child {
      min-width: 225px;
      display: flex;
      align-items: center;
      column-gap: 18px;
      position: relative;
      left: 28px;

      @media (max-width: '1400px') {
        left: 18px;
      }
      @media (max-width: $md) {
        min-width: auto;
        position: static;
        column-gap: 12px;
      }
      &:lang(ar) {
        right: 28px;

        @media (max-width: '1400px') {
          right: 18px;
        }
      }
    }
    @media (max-width: $md) {
      padding: 0;
      img {
        width: 20px;
      }
    }
    @media (max-width: $sm) {
      img {
        width: 17px;
      }
    }
  }
  .errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 79px 0 14px;
    .errorImage {
      padding-bottom: 16px;
    }
    p {
      margin-bottom: 0;
    }
    .donateBtn {
      padding-top: 51px;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }

  .purposeCol {
    display: flex;
    column-gap: 18px;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 13px 0;
    div:first-child {
      min-width: 225px;
      display: flex;
      align-items: center;
      column-gap: 18px;
      position: relative;
      left: 28px;

      @media (max-width: '1400px') {
        left: 18px;
      }
      @media (max-width: $md) {
        min-width: auto;
        position: static;
        column-gap: 12px;
      }
      &:lang(ar) {
        right: 28px;

        @media (max-width: '1400px') {
          right: 18px;
        }
      }
    }
    @media (max-width: $md) {
      padding: 0;
      img {
        width: 20px;
      }
    }
    @media (max-width: $sm) {
      img {
        width: 17px;
      }
    }
  }
}


.purposeIcon {
  max-width: 30px;
}