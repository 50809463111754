@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.counter {
    display: flex;
    column-gap: 17px;

    .counterDec,
    .counterIec {
        border: 1px solid $color-primary;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;

        span {
            padding-top: 3px;
        }
    }
}