@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.childFamily {
    .childTitle {
        font-size: 16px;
        font-weight: bold;
        color: $color-black;
        text-transform: uppercase;
        font-family: 'Univers';
        display: flex;
        align-items: center;
        letter-spacing: 1.6px;
        column-gap: 13px;

        p {
            margin: 0;
        }

        &:lang(ar) {
            letter-spacing: normal;
        }
    }

    .childFamilyDetails {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        margin-top: 10px;

        .childFamilyMember {
            display: flex;
            column-gap: 17px;
            font-family: 'Univers';
            font-size: 18px;
            line-height: normal;
            letter-spacing: normal;
            color: $color-primary;

            span {
                color: $color-secondary-500;
                min-width: 165px;
                letter-spacing: -0.9px;

                @media (max-width: $xs) {
                    min-width: 120px;
                }
            }
        }
    }
}

@media (max-width: $md) {
    .childFamily {
        .childFamilyDetails {
            row-gap: 15px;
            margin-top: 15px;

            .childFamilyMember {
                column-gap: 12px;
                font-size: 14px;

                span {
                    letter-spacing: -0.7px;
                }
            }
        }
    }
}