@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-tabs .ant-tabs-ink-bar {
    display: none;
}

.ant-tabs .ant-tabs-tab {
  font-size: 1rem;
  font-weight: normal;
  color: $color-primary;
  text-transform: uppercase;
  padding: 20px 55px 15px;
  
  @media (max-width: 1400px) {
    font-size: 0.75rem;
  }
  
  @media (max-width: $lg) {
    padding: 20px 12px 15px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  @media (max-width: $sm) {
    padding: 10px;
  }
  @media (max-width: 400px) {
    font-size: 8px;
  }
}

.ant-tabs .ant-tabs-tab-active {
  border-radius: 5px;
  background-color: $color-white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none
}

.ant-tabs-nav-wrap {
  justify-content: end;
}

.ant-tabs > .ant-tabs-nav {
  @media (max-width: $md) {
    margin-top: 50px;
    margin-bottom: 97px;
  }
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  @media (max-width: $md) {
    width: 100%;
    justify-content: space-around;
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0px;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}