@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.signinWrapper {
  display: flex;
  font-family: 'Univers';
  padding-top: 81px;
  padding-right: 116px;
  padding-left: 116px;
  padding-bottom: 278px;
  column-gap: 41px;
  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 458px;
  }
  @media (max-width: '406px') {
    padding-bottom: 477px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 500px;
  }
  .signinImage {
    width: 32%;
    background-image: url('/assets/images/signIn-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: solid 1px $color-secondary-500;
    border-radius: 20px;
  }
  .signinForm {
    width: 68%;
    .formWrapper {
      .form {
        padding: 55px 0 51px;
        .formInputs {
          display: flex;
          flex-direction: column;
          row-gap: 36px;
        }
        .forgetPassword {
          display: flex;
          justify-content: flex-end;
        }
        .forgetPasswordLink {
          width: 50%;
          display: flex;
          justify-content: flex-end;
          color: $color-primary-500;
          font-size: 1.125rem;
          font-weight: normal;
          margin-top: -40px;
          @media (max-width: $xs) {
            width: 100%;
          }
        }
        .signinBtn {
          display: flex;
          justify-content: center;
          margin-top: -4px;
        }
        .signinRedirect {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-family: 'Univers';
          font-size: 1.125rem;
          font-weight: normal;
          margin-top: -7px;
          a {
            font-weight: bold;
            color: $color-primary-500;
          }
        }
      }
      @media (max-width: $md) {
        .form {
          padding: 24px 0 37px;
          .formInputs {
            row-gap: 10px;
          }
          .forgetPasswordLink,
          .signinRedirect {
            font-size: 0.875rem;
          }
          .forgetPasswordLink {
            margin-top: -28px;
          }
          .signinRedirect {
            margin-top: -12px;
          }
          .signinBtn {
            margin-top: 16px;
          }
        }
      }
    }
  }

  @media (max-width: $lg) {
    .signinImage {
      display: none;
    }
    .signinForm {
      width: 100%;
    }
  }
  &::lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
