@import '../../../../../theme/colors.scss';
@import '../../../../../theme/breakpoints.scss';

.sponsorshipWrapper {
  background-color: $color-white;
  padding-top: 40px;
  @media (max-width: $md) {
    margin-left: -30px;
    margin-right: -30px;
  }
  @media (max-width: $sm) {
    padding: 40px 10% 0;
  }

  .sponsorshipImage {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    img {
      max-width: 100%;
    }
    @media (max-width: $sm) {
      padding: 50px 0;
    }
  }
}
