@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.statisticWrapper {
  .statistic {
    width: 100%;
    background-image: url('../../../../../assets/images/statistics-bg-xxlarge.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 65px 75px;
    @media (max-width: $xl) {
      background-image: url('../../../../../assets/images/statistics-bg-large.png');
      row-gap: 70px !important;
      padding: 85px 0 60px;
    }
    @media (max-width: $md) {
      background-image: url('../../../../../assets/images/statistics-bg-medium.png');
    }
    @media (max-width: $sm) {
      row-gap: 35px !important;
    }
    .statisticInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      .statisticInfoNumber {
        span {
          font-family: 'Univers Bold';
          font-weight: bold;
          font-size: 4.8125rem;
          color: $color-white;
          @media (max-width: $md) {
            font-size: 3.4375rem;
          }
          @media (max-width: $sm) {
            font-size: 38.5px;
          }
          &:lang(ar) {
            font-family: 'NotoSansArabic Bold';
          }
        }
      }
      .statisticInfoName {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 1.375rem;
        margin-top: -35px;
        color: $color-white;
        font-family: 'Univers Light';
        font-weight: 300;
        &:lang(ar) {
          margin-top: -30px;
          @media (max-width: $md) {
            margin-top: -15px;
          }
          @media (max-width: $sm) {
            margin-top: -10px;
            column-gap: 5px;
          }
        }
        @media (max-width: $md) {
          margin-top: -25px;
        }
        @media (max-width: $sm) {
          margin-top: -15px;
          column-gap: 5px;
        }
        .statisticIcon {
          img {
            @media (max-width: $md) {
              max-width: 25px;
            }
            @media (max-width: $sm) {
              max-width: 18px;
            }
          }
        }
        .statisticName {
          margin-top: 5px;
          @media (max-width: $sm) {
            margin-top: 2px;
          }
        }
        @media (max-width: $xxl) {
          font-size: 1.1875rem;
        }
        @media (max-width: $sm) {
          font-size: 1.0625rem;
        }
        &:lang(ar) {
          font-family: 'NotoSansArabic Light';
        }
      }
    }
  }
  @media (max-width: $md) {
    margin-left: -30px;
    margin-right: -30px;
  }
}
