@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    &::after {
      height: 11.142857px;
    }
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: $color-secondary-500;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: $color-white;
}
