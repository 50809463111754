@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.infoCardWrapper {
    font-family: Univers;

    border-radius: 20px;
    background-color: $color-white;

    @media (max-width: $md) {
        margin-top: 34px;
    }

    .infoCard {

        padding: 32px 100px;

        @media (max-width: $md) {
            padding: 32px 22px;
        }

        .infoCardTitle {
            color: $color-black;
            padding-bottom: 14px;
            border-bottom: 1px solid $color-secondary-500;

            h1 {
                font-size: 1rem;
                font-weight: bold;
                letter-spacing: 1.6px;
                text-align: center;
                text-transform: uppercase;

                @media (max-width: $md) {
                    font-size: 0.75rem;
                }

                &:lang(ar) {
                    letter-spacing: normal;
                }
            }
        }
    }

    &:lang(ar) {
        font-family: 'NotoSansArabic';
    }

}