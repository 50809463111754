@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.statementOfFaithWrapper {
  padding-bottom: 300px;
  @media (max-width: $lg) {
    padding-bottom: 490px;
  }
  @media (max-width: $sm) {
    padding-bottom: 460px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }
}
