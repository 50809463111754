@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.giftCardWrapper {
  .giftCard {
    border-radius: 15px;
    border: solid 1px $color-secondary-500;
    overflow: hidden;
    position: relative;

    .giftCardToaster {
      width: 100%;
      top: 0px;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      position: absolute;

      transition: top 0.3s ease;

      span {
        padding-top: 5px;
        color: $color-white;
        font-size: 1rem;

        @media (max-width: $md) {
          font-size: 10px;
        }
      }

      img {
        width: 23px;
        @media (max-width: $md) {
          width: 12px;
        }
      }
    }

    .giftCardToasterSucces {
      background-color: $color-success;
    }

    .giftCardToasterFail {
      background-color: $color-error;
    }

    .giftCardToasterHide {
      top: -80px;
    }

    .giftCardImage {
      display: flex;
      height: 296px;

      img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
      @media (max-width: $xs) {
        height: 200px;
      }
    }

    .giftCardBottom {
      display: flex;
      justify-content: space-between;
      padding: 18px 30px;

      @media (max-width: $md) {
        padding: 10px 14px;
      }

      .giftCardBottomInfo {
        h4,
        p {
          margin: 0px;
        }

        h4 {
          font-size: 1.5625rem;
          font-weight: bold;
          color: $color-primary;
          margin-bottom: 5px;
          
          @media (max-width: $lg) {
            font-size: 0.875rem;
          }
        }

        p {
          font-size: 1.125rem;
          color: $color-gray;
          margin-top: -10px;

          @media (max-width: $md) {
            font-size: 0.75rem;
            margin-top: 0;
          }
        }
      }

      .giftCardBottomIcon {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 40px;
          @media (max-width: $md) {
            width: 26px;
          }
        }
      }
    }
  }
}
