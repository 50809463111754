@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.myDonationsWrapper {
  font-family: 'Univers';
  padding: 53px 220px 270px;
  @media (max-width: $xxl) {
    padding: 65px 150px 250px;
  }
  @media (max-width: '1200px') {
    padding: 65px 70px 250px;
  }
  @media (max-width: $lg) {
    padding: 53px 70px 450px;
  }
  @media (max-width: $md) {
    padding: 0 29px 493px;
    margin: -30px;
  }
  @media (max-width: '390px') {
    padding: 0 29px 523px;
  }
  .myDonationsTitle {
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $color-primary;
    p {
      margin-bottom: 24px;
    }
    @media (max-width: $xxl) {
      font-size: 2.3125rem;
      p {
        margin-bottom: 10px;
      }
    }
    @media (max-width: $md) {
      font-size: 1.25rem;
      text-align: center;
      line-height: 2.5;
      padding-top: 44px;
    }
  }
  .donationTotalPrice {
    font-size: 1rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-primary;
    text-transform: uppercase;
    @media (max-width: $md) {
      font-size: 0.75rem;
    }
  }
  .donationStatus {
    min-width: 90px;
  }
  .purposeWrapper {
    display: flex;
    column-gap: 18px;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 13px 0;
    .purpose {
      .purposeIcon {
        max-width: 30px;
      }
      .purposeText {
        text-align: left;
        font-size: 1rem;
      }
    }
    div:first-child {
      min-width: 225px;
      display: flex;
      align-items: center;
      column-gap: 18px;
      position: relative;
      @media (max-width: $md) {
        min-width: auto;
        position: static;
        column-gap: 12px;
      }
    }
    @media (max-width: $md) {
      padding: 0;
      img {
        width: 20px;
      }
    }
    @media (max-width: $sm) {
      img {
        width: 17px;
      }
    }
  }

  .myDonationsPagination {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    @media (max-width: $md) {
      margin-top: 54px;
    }
  }
  .errorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 79px 0 14px;
    .errorImage {
      padding-bottom: 16px;
    }
    p {
      margin-bottom: 0;
    }
    .donateBtn {
      padding-top: 51px;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}