@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.dashboard {
    font-family: Univers;
    padding-top: 87px;
    padding-right: 116px;
    padding-left: 116px;
    padding-bottom: 254px;
    column-gap: 41px;

    @media (max-width: $xxl) {
        padding-top: 100px;
        padding-right: 58px;
        padding-left: 58px;
    }

    @media (max-width: $xl) {
        padding-top: 51px;
    }

    @media (max-width: $lg) {
        padding-bottom: 517px;
    }

    @media (max-width: $md) {
        margin: -30px;
        padding-top: 44px;
        padding-right: 29px;
        padding-left: 29px;
    }

    @media (max-width: '632px') {
        padding-bottom: 547px;
    }

    @media (max-width: $sm) {
        padding-bottom: 519px;
    }

    @media (max-width: '406px') {
        padding-bottom: 485px;
    }

    &:lang(ar) {
        font-family: NotoSansArabic;
    }
}

.rowSection {
    margin-top: 38px;
}

.logOutButton {
    margin-top: 14px;
    @media (max-width: $lg) {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
}