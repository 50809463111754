@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.profileWrapper {
  font-family: 'Univers';
  padding-top: 81px;
  padding-right: 210px;
  padding-left: 210px;
  padding-bottom: 260px;
  column-gap: 41px;

  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }

  @media (max-width: '632px') {
    padding-bottom: 472px;
  }

  @media (max-width: $sm) {
    padding-bottom: 458px;
  }

  @media (max-width: '406px') {
    padding-bottom: 510px;
  }

  .profileHeader {
    @media (max-width: $md) {
      text-align: center;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      color: $color-primary;

      @media (max-width: $md) {
        font-size: 1.25rem;
      }
    }
  }

  .profileInfo {
    padding-top: 70px;
    @media (max-width: $md) {
      padding-top: 12px;
    }

    .profileBasicInfo {
      padding: 12px;

      .profileBasic {
        width: fit-content;
        @media (max-width: $xl) {
          width: 100%;
        }

        .profileBasicInfoPhoto {
          display: flex;
          justify-content: center;

          img {
            width: 90px;
            height: 90px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .profileBasicInfoName {
          font-family: Univers;
          display: flex;
          justify-content: center;
          font-size: 1.25rem;
          font-weight: bold;
          text-align: center;
          color: $color-primary;
          margin-top: 17px;

          @media (max-width: $xl) {
            font-size: 1rem;
          }
        }

        .profileBasicInfoEmail {
          font-family: Univers;
          display: flex;
          justify-content: center;
          font-family: Univers;
          font-size: 1rem;
          color: $color-gray;

          @media (max-width: $xl) {
            font-size: 0.75rem;
            color: $color-secondary-500;
          }
        }

        .profileBasicInfoUpdateProfile {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }

    .profilerFullInfo {
      padding: 12px;

      .fullInfo {
        display: flex;
        justify-content: start;
        column-gap: 10px;
        margin-bottom: 25px;

        .fullInfoKey {
          font-size: 1.125rem;
          letter-spacing: -0.9px;
          color: $color-secondary-500;
          min-width: 50%;

          @media (max-width: $md) {
            font-size: 0.875rem;
          }
        }

        .fullInfoValue {
          font-family: Univers;
          font-size: 1.125rem;
          color: $color-primary;
          text-transform: capitalize;
          word-wrap: break-word;

          @media (max-width: $md) {
            font-size: 0.875rem;
          }
        }

        .fullInfoValueNumber {
          direction: ltr;
        }
      }
    }

    .profileButtonInfo {
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: end;
      row-gap: 31px;
      flex-direction: column;
      @media (max-width: $xl) {
        row-gap: 13px;
        align-items: center;
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
