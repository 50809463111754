@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.GiftSection {
    padding-bottom: 42px;
    @media (max-width: $md) {
        padding-bottom: 39px;
    }

    .giftSectionHeader {
        display: flex;
        column-gap: 18px;
        align-items: center;
        h1 {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.88;
            letter-spacing: 1.6px;
            color: $color-black;
            text-transform: uppercase;
            margin-bottom: 0px;

            &:lang(ar) {
                letter-spacing: normal;

            }

            @media (max-width: $md) {
                font-size: 0.75rem;
            }
        }
    }

    .giftRowWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 25px;

        .giftRow {
            display: flex;
            justify-content: space-between;
            border-radius: 15px;
            overflow: hidden;
            background-color: $color-white;

            @media (max-width: $md) {
                flex-direction: column;
            }

            .giftRowContent {
                padding: 32px 37px 14px;
                width: 100%;
                
                @media (max-width: $sm) {
                    padding: 32px 26px 14px;
                }

                .giftRowContentRow {
                    row-gap: 23px !important;

                    .giftRowContentCol {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        row-gap: 23px;

                        .cellLabelCol {
                            display: flex;
                            align-items: center;
                            column-gap: 5px;
                            .cellLabel {
                                font-size: 1rem;
                                letter-spacing: 1.6px;
                                color: $color-secondary-500;
                                text-transform: uppercase;

                                &:lang(ar) {
                                    letter-spacing: normal;
                                }

                                @media (max-width: $md) {
                                    font-size: 0.75rem;
                                }
                            }

                            .cellLabelColQuantitiy {
                                position: absolute;
                                left: -23px;
                                
                                &:lang(ar) {
                                    right: -23px;
                                    left: inherit;
                                }
                            }

                        }

                        .cellValue {
                            font-size: 1.125rem;
                            font-weight: bold;
                            color: $color-primary;

                            @media (max-width: $md) {
                                font-size: 0.875rem;
                            }
                        }

                    }

                    .giftRowContentColFirst {
                        padding: 0px 40px 0px 0px;
                    }
                }
            }

            .giftRowDelete {
                background-color: $color-primary;
                padding: 50px 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                @media (max-width: $md) {
                    padding: 13px 32px;
                }
            }
        }
    }

    .giftSectionButton {
        margin: 36px 0px;
        @media (max-width: $md) {
            display: flex;
            justify-content: center;
            margin: 39px 0px 0px 0px;
        }
    }
}