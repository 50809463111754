.projectImages {
    
    .slick-dots li {
        opacity: 0;
        transition: opacity 0.8s linear;
        display: none !important;
    }

    // active
    li.slick-active {
        opacity: 1;
        display: block !important;
    }
    // prev 2
    li:has(+ li + li.slick-active),
    // prev 1
    li:has(+ li.slick-active),
    // next 1
    li.slick-active+li,
    // next 2
    li.slick-active+li+li {
        opacity: 0.5;
        display: block !important;
    }
}