@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.lateDuesCheckoutWrapper {
  font-family: 'Univers';
  margin-top: 60px;
  margin-bottom: 255px;
  padding-right: 116px;
  padding-left: 116px;
  @media (max-width: $xxl) {
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    margin-bottom: 470px;
    .cards {
      margin-top: 21px;
    }
  }
  @media (max-width: $md) {
    margin-top: 14px;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 430px;
  }
  @media (max-width: $sm) {
    margin-bottom: 450px;
  }
  @media (max-width: '390px') {
    margin-bottom: 435px;
  }
  @media (max-width: '372px') {
    margin-bottom: 455px;
  }
  @media (max-width: $xxs) {
    margin-bottom: 480px;
  }
  @media (max-width: $xs) {
    margin-bottom: 485px;
  }
  .checkoutTitle {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
    margin-bottom: 60px;
    p {
      margin-bottom: 0;
    }
    @media (max-width: $lg) {
      text-align: center;
    }
    @media (max-width: $md) {
      font-size: 1.25rem;
      margin-bottom: 29px;
    }
    &:lang(ar) {
      text-align: right;
    }
  }
  .existingCardsForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    row-gap: 16px;

    @media (max-width: $lg) {
      row-gap: 17px;
    }
    @media (max-width: $md) {
      padding-top: 14px;
    }
    @media (max-width: $sm) {
      margin-right: -17px;
      margin-left: -17px;
    }
    .addNewCardBtns {
      display: flex;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 55px;
      @media (max-width: $md) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
    .emptyAddNewCard {
      padding-top: 20px;
      padding-bottom: 41px;
      @media (max-width: $md) {
        padding-top: 16px;
        padding-bottom: 30px;
      }
    }
  }
  .checkoutContentWrapper {
    .summary {
      display: flex;
      justify-content: space-between;
      font-size: 1.125rem;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: $color-primary;
      margin-top: 40px;
      @media (max-width: $md) {
        font-size: 0.875rem;
        margin-top: 20px;
        margin-bottom: 2px;
      }
    }
  }

  .submitBtn {
    display: flex;
    justify-content: center;
    margin-top: 63px;
    @media (max-width: $md) {
      margin-top: 35px;
    }
  }
}
