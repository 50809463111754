@import './colors.scss';
@import 'theme/breakpoints.scss';

.ant-form-item {
  .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-item-label {
    padding: 0 0 5px;
    text-align: initial;

    label {
      font-family: 'Univers';
      font-size: 20px !important;
      font-weight: bold;
      color: $color-primary;

      &:lang(ar) {
        font-family: 'NotoSansArabic';
      }

      &::before {
        display: none !important;
      }

      @media (max-width: $xxl) {
        font-size: 18px !important;
      }

      @media (max-width: $sm) {
        font-size: 16px !important;
      }
    }
  }

  .ant-form-item-control {
    max-width: 100%;
  }

  .ant-input-number,
  .ant-input-password,
  .ant-input-affix-wrapper,
  .ant-input-password,
  .ant-input {
    width: 100%;
    border-radius: 10px;
    font-family: 'Univers';
    border: solid 1px $color-secondary-500;
    background-color: $color-white;
    font-size: 1.25rem;
    padding: 20px 50px;
    transition: none;

    @media (max-width: $xxl) {
      font-size: 1.125rem;
    }

    @media (max-width: $sm) {
      font-size: 1rem;
      padding: 14px 17px;
    }
  }

  .ant-input-affix-wrapper-status-success {
    .ant-input {
      margin-top: 8px;
    }
  }

  .ant-input,
  .ant-input-number {
    padding: 20px 50px !important;

    @media (max-width: $md) {
      padding: 20px !important;
    }
  }
  .donationInputNumber {
    padding: 17px 80px !important;
    @media (max-width: $sm) {
      padding: 15px 40px !important;
    }
    @media (max-width: '390px') {
      padding: 15px 65px !important;
    }
    @media (max-width: '320px') {
      padding: 15px 50px !important;
    }
  }
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-success,
  .ant-input-affix-wrapper-status-error {
    padding: 5px 50px;
    .ant-input {
      margin-top: 8px;
      font-size: 2.1875rem;
      letter-spacing: 10px;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      &:placeholder-shown {
        font-size: 1.125rem;
        letter-spacing: normal;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
    @media (max-width: $sm) {
      padding: 5px 17px;
      .ant-input {
        font-size: 1.75rem;
        letter-spacing: 6px;
      }
    }
    &:label(ar) {
      font-family: 'NotoSansArabic';
    }
  }
}

input:-webkit-autofill {
  border: none;
  -webkit-text-fill-color: $color-primary;
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
  
  &[class*=" ant-input-status-error"] {    
    -webkit-text-fill-color: $color-error;
  }

  &::selection {
    background: $color-background !important;
  }
}

.ant-input-affix-wrapper {
  padding: 20px 50px;

  .ant-input {
    background-color: $color-white !important;
  }

  .ant-input-suffix {
    display: none;
  }
}
.ant-input,
.ant-input-number {
  padding: 20.5px 50px !important;
}
.childrenSearchInput {
  .ant-input {
    padding: 18px 50px !important;
    @media (max-width: $xl) {
      padding: 15px 15px !important;
    }
    @media (max-width: $sm) {
      padding: 13px 50px !important;
    }
  }
}

.amountWrapper {
  .ant-input-number-input {
    font-weight: bold;
  }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-affix-wrapper {
  padding: 0px !important;
}

.ant-input-suffix {
  display: none !important;
}

.ant-form-item-has-error {
  .ant-form-item-label {
    label {
      color: $color-error;
    }
  }

  .ant-form-item-explain {
    div {
      padding-top: 10px;
      color: $color-error;
      font-family: 'Univers';
      font-size: 1rem;
    }
  }
}

.ant-input-status-error,
.ant-select-status-error .ant-select-selector,
.ant-input-affix-wrapper-status-error {
  color: $color-error;
  border: 2px solid $color-error !important;
}

.ant-input-affix-wrapper-status-error input {
  color: $color-error;
}

.phoneNumberMask .ant-input-number {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

  height: 73px;

  &:lang(ar) {
    border-left: solid 1px #063060 !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-right: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

input:-webkit-autofill {
  border: none;
  -webkitransitiont-text-fill-color: $color-primary;
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;

  &::selection {
    background: $color-background !important;
  }
}

.ant-input-affix-wrapper {
  padding: 20px 50px;

  .ant-input {
    background-color: $color-white !important;
  }

  .ant-input-suffix {
    display: none;
  }
}

.ant-form-item-has-error {
  .phoneNumberMask .ant-input-number {
    border: 2px solid $color-error !important;
    border-left: none !important;

    height: 75px;

    &:lang(ar) {
      border-left: 2px solid $color-error !important;
      border-right: none !important;
    }
  }
}

.ant-form-item-has-error {
  .maskedInput input {
    border: 2px solid $color-error !important;
  }
}

.ant-form-item-label {
  label {
    color: $color-error;
  }
}

.ant-form-item-explain {
  div {
    padding-top: 10px;
    color: $color-error;
    font-family: 'Univers';
    font-size: 1rem;
  }
}

.ant-input-status-error,
.ant-select-status-error .ant-select-selector,
.ant-input-affix-wrapper-status-error {
  color: $color-error;
  border: 2px solid $color-error !important;
}

.completeForm,
.loginForm,
.forgetForm,
.resetForm {
  .ant-form {
    .ant-form-item {
      .ant-form-item-label::before {
        content: '*';
        font-size: 1.125rem;
        font-weight: bold;
        color: $color-primary;
        margin: 0 5px;
      }
    }

    .ant-form-item-has-error {
      .ant-form-item-label::before {
        color: $color-error;
      }
    }
  }
}

.ant-input {
  &:placeholder-shown {
    direction: ltr !important;
    &:lang(ar) {
      direction: rtl !important;
    }
  }
}

.ant-form-item .ant-form-item-label label:lang(ar) {
  margin-bottom: 10px;
}

.formStepWrapper {
  .ant-input {
    color: $color-primary;
  }
}

.cellValue {
  .ant-form-item .ant-input {
    padding: 13px 32px !important;
    font-size: 1rem;
    &:placeholder-shown {
      font-size: 1rem;
      color: $color-gray;
    }
    &::placeholder {
      font-size: 1rem;
      color: $color-gray;
    }

    &:-ms-input-placeholder {
      font-size: 1rem;
      color: $color-gray;
    }

    &::-ms-input-placeholder {
      font-size: 1rem;
      color: $color-gray;
    }

    @media (max-width: $md) {
      padding: 2px 32px !important;
    }
  }
}

.cellValue {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-select {
    max-width: 190px;
  }
  .ant-select-selector {
    background-position: right 1rem center;
    &:lang(ar) {
      background-position: left 1rem center;
    }
  }
  .ant-select .ant-select-selector {
    padding: 8px 36px 8px 20px !important;
    font-size: 1rem;
    color: $color-gray;
    max-width: 190px;

    @media (max-width: $md) {
      padding: 0px 25px 0px 20px !important;
      font-size: 0.75rem;
    }
  }

  .ant-select-selection-placeholder {
    color: $color-gray;
  }
}
