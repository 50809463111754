@import '../../../../../theme/colors.scss';
@import '../../../../../theme/breakpoints.scss';

.HowWeWorkWrapper {
  background-color: $color-white;
  padding: 45px 116px 0;
  @media (max-width: $xxl) {
    padding: 45px 58px 0;
  }
  @media (max-width: $md) {
    padding: 25px 29px 0;
    margin-left: -30px;
    margin-right: -30px;
  }
  .HowWeWorkVideo{
    border-width: 0;
    @media (max-width:$lg){
      height: 400px;
    }
    @media (max-width:$sm){
      height: 200px;
    }
    @media (max-width:'375px'){
      height: 180px;
    }

  }
}
