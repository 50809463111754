@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.donateWrapper {
  position: relative;
  background-color: $color-white;
  .donateBG {
    position: relative;
    margin-top: 70px;
    img {
      width: 100%;
      height: 700px;
      @media (max-width: $xl) {
        height: 750px;
      }

      @media (max-width: $sm) {
        height: 500px;
      }
      @media (max-width: $xs) {
        height: 650px;
      }
      @media (max-width: $xxs) {
        height: 700px;
      }
      &:lang(ar) {
        height: 750px;
        @media (max-width: $xl) {
          height: 800px;
        }
        @media (max-width: $sm) {
          height: 600px;
        }
        @media (max-width: $xxs) {
          height: 700px;
        }
      }
    }
  }
  .donate {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 66px;
    position: absolute;
    bottom: 0;
    top: 0;
    @media (max-width: $xxl) {
      row-gap: 40px;
    }
    @media (max-width: $sm) {
      padding: 200px 0;
    }
    @media (max-width: $xs) {
      padding: 300px 0;
    }
    @media (max-width: $xs) {
      top: -30px;
    }
    .donateTitle {
      display: flex;
      justify-content: center;
      align-self: center;
      padding: 0px 18%;
      column-gap: 5%;
      .donateTitleImage {
        img {
          max-width: 125px;
          @media (max-width: $lg) {
            max-width: 85px;
          }
          @media (max-width: $sm) {
            max-width: 55px;
          }
        }
      }
      .donateTitleText {
        font-family: 'Univers Bold';
        font-weight: bold;
        font-size: 2.5rem;
        color: $color-white;
        line-height: normal;
        p {
          margin-bottom: 0;
        }
        &:lang(ar) {
          font-family: 'NotoSansArabic Bold';
          line-height: 1.5;
        }
      }
      @media (max-width: $xxl) {
        .donateTitleText {
          font-size: 2.5rem;
        }
      }
      @media (max-width: $xl) {
        .donateTitleText {
          font-size: 1.875rem;
        }
      }
      @media (max-width: $md) {
        .donateTitleText {
          font-size: 1.25rem;
        }
      }
      @media (max-width: $sm) {
        padding: 0 8%;
        .donateTitleText {
          font-size: 0.9375rem;
        }
      }
    }
    .donateList {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      line-height: normal;
      .donateListItem {
        display: flex;
        align-items: center;
        column-gap: 25px;
        color: $color-white;
        font-family: 'Univers';
        font-size: 1.25rem;
        @media (max-width: $xxl) {
          font-size: 1.125rem;
        }
        @media (max-width: $sm) {
          column-gap: 15px;
        }
        img {
          width: 30px;
          @media (max-width: $md) {
            width: 23px;
          }
        }
        &:lang(ar) {
          font-family: 'NotoSansArabic';
        }
      }
      @media (max-width: $lg) {
        padding: 0 15%;
      }
      @media (max-width: $md) {
        padding: 0 70px;
        row-gap: 25px;
      }
      @media (max-width: $sm) {
        padding: 0 50px;
        .donateListItem {
          font-size: 1rem;
          img {
            width: 18px;
          }
        }
      }
    }
  }
  @media (max-width: $md) {
    margin-left: -30px;
    margin-right: -30px;
  }
}
