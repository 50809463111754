@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.cartWrapper {
    font-family: 'Univers';
    padding-top: 100px;
    padding-right: 116px;
    padding-left: 220px;
    padding-bottom: 205px;

    &:lang(ar) {
        padding-left: 116px;
        padding-right: 220px;
    }

    @media (max-width: $xxl) {
        padding-top: 86px !important;
        padding-right: 58px !important;
        padding-left: 58px !important;
    }

    @media (max-width: '1260px') {
        padding-top: 51px !important;
    }

    @media (max-width: $lg) {
        padding-bottom: 447px !important;
    }

    @media (max-width: $md) {
        margin: -30px !important;
        padding-top: 34px !important;
        padding-right: 29px !important;
        padding-left: 29px !important;
    }

    @media (max-width: '632px') {
        padding-bottom: 472px !important;
    }

    @media (max-width: $sm) {
        padding-bottom: 458px !important;
    }

    @media (max-width: '406px') {
        padding-bottom: 505px !important;
    }

    .cartTitle {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.25;
        color: $color-primary;
        text-transform: capitalize;

        @media (max-width: $md) {
            font-size: 1.25rem;
            text-align: center;
            line-height: 2.5;
            padding-top: 10px;
        }

        p {
            margin-bottom: 25px;
            
            @media (max-width: $md) {
                margin-bottom: 30px;
            }
        }

        .alertWrapper {
            margin-bottom: 24px;
            width: 40%;
            @media (max-width: $md) {
                width: 100%;
            }
        }
    }

    .cart {
        .cartContent {
            .childrenSection {
                border-bottom: 1px solid $color-offwhite;
                padding-bottom: 35px;
                @media (max-width: $md) {
                    padding-bottom: 8px;
                }
            }
            .checksWrapper{
                margin-top: 20px;
                margin-inline-start: 10px;
            }
            .giftSection {
                margin-top: 35px;
                @media (max-width: $md) {
                    margin-top: 38px;
                }
            }
        }
    }

    .checkoutButton {
        display: flex;
        justify-content: end;
        margin-top: 90px;
        position: absolute;
        bottom: 77px;
        width: 100%;
        padding: 0px 40px;
        @media (max-width: $lg) {
            position: static;
            bottom: 60px;
            margin-top: 40px;
            margin-bottom: 40px;
            justify-content: center;

        }
        @media (max-width: $xs) {
            position: static;
            bottom: 60px;
            margin-top: 40px;
            margin-bottom: 40px;
            justify-content: center;

        }
        @media (max-width: 375px) {
            position: static;
            bottom: 60px;
            margin-top: 40px;
            margin-bottom: 0px;
            justify-content: center;

        }
      
    }
    &:lang(ar) {
      font-family: NotoSansArabic;
    }
}