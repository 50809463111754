@import './pagination.scss';
@import './collapse.scss';
@import '/carousel.scss';
@import './select.scss';
@import './radio.scss';
@import './searchInput.scss';
@import './input.scss';
@import './checkbox.scss';
@import './uploader.scss';
@import './radio.scss';
@import './slick.scss';
@import './radio.scss';
@import './uploader.scss';
@import './table.scss';
@import './modal.scss';
@import './tabs.scss';

html:lang(ar) {
  font-family: 'NotoSansArabic';
}

.ant-layout {
  position: relative;
  min-height: 100vh;
}

.ant-menu::after {
  content: none;
}

ul {
  .ant-pagination-item-active {
    &:hover {
      a {
        color: $color-white;
      }
    }

    background-color: $color-primary;

    a {
      color: $color-white;
    }
  }

  .ant-pagination-jump-next {
    a {
      width: 100%;

      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
          opacity: 1 !important;
          color: $color-primary;
          font-size: 0.75rem;
        }

        .anticon-double-right {
          visibility: hidden;
        }
      }
    }
  }

  .ant-pagination-jump-prev {
    a {
      width: 100%;

      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
          opacity: 1 !important;
          color: $color-primary;
          font-size: 0.75rem;
        }

        .anticon-double-right {
          visibility: hidden;
        }
      }
    }
  }

  .ant-pagination-prev {
    border: none !important;
  }
}

.dollarSymbol {
  font-family: 'Univers' !important;
}

.poundSymbol:lang(ar) {
  font-family: 'NotoSansArabic' !important;
}

.UpdateProfileInfoCardWrapper .infoCardWrapper {
  border: 1px solid $color-secondary-500;
}

.cardContent {
  div {
    display: inline !important;
  }
}

.cartSummary {
  margin-top: 47px;
  border: solid 1px $color-secondary-500;
  .infoCard {
    padding: 32px 70px;
  }
  @media (max-width: $xl) {
    .infoCard {
      padding: 32px 15px;
    }
  }
  @media (max-width: $md) {
    margin-top: 0px;
    .infoCard {
      padding: 22px;
    }
  }
}

.lateDuesSummary {
  margin-top: 0;
  @media (max-width: $xl) {
    .infoCard {
      padding: 32px 30px;
    }
  }
  @media (max-width: $lg) {
    .infoCard {
      padding: 32px 70px;
    }
  }
  @media (max-width: $md) {
    .infoCard {
      padding: 18px 30px 24px;
    }
  }
}
