@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.sectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0 50px;
  .sectionHeaderText {
    article {
      font-family: 'Univers';
      font-size: 3.125rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 5px;
      text-transform: uppercase;
      color: $color-primary;
      text-align: center;
      &:lang(ar) {
        font-family: 'NotoSansArabic';
        letter-spacing: 0;
      }
    }
    @media (max-width: $xxl) {
      font-size: 45px;
    }
  }
  .sectionHeaderImage {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    &:lang(ar) {
      img {
        transform: rotateY(180deg);
      }
    }
  }
}
@media (max-width: $md) {
  .sectionHeader {
    padding: 36px 35px 50px;
    .sectionHeaderText {
      article {
        font-size: 1.875rem;
        line-height: normal;
        letter-spacing: 2.5px;
        @media (max-width: $xs) {
          font-size: 1.5625rem;
        }
      }
    }
    .sectionHeaderImage {
      img {
        width: 60%;
      }
    }
  }
}
