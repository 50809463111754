@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.updateProfileWrapper {
    font-family: 'Univers';
    padding-top: 81px;
    padding-right: 116px;
    padding-left: 116px;
    padding-bottom: 260px;

    @media (max-width: $xxl) {
        padding-top: 100px;
        padding-right: 58px;
        padding-left: 58px;
    }

    @media (max-width: $lg) {
        padding-bottom: 447px;
    }

    @media (max-width: $md) {
        margin: -30px;
        padding-top: 34px;
        padding-right: 29px;
        padding-left: 29px;
    }

    @media (max-width: '632px') {
        padding-bottom: 472px;
    }

    @media (max-width: $sm) {
        padding-bottom: 458px;
    }

    @media (max-width: '406px') {
        padding-bottom: 477px;
    }



    .updateProfileHeader {
        display: flex;
        column-gap: 30px;
        cursor: pointer;
        @media (max-width: $lg) {
            justify-content: center;
            position: relative;
        }

        img {
            width: 60px;
            @media (max-width: $lg) {
                width: 32px;
                position: absolute;
                left: 0px;
            }

            &:lang(ar) {
                transform: rotate(180deg);
                left: inherit;
                right: 0;
            }
        }

        p {
            margin: 0px;
            font-size: 2.5rem;
            font-weight: bold;
            color: #032f61;

            @media (max-width: $lg) {
                font-size: 1.25rem;
            }
        }
    }

    .updateProfile form {
        display: flex;
        flex-direction: column;
        row-gap: 60px;
        margin: 50px 268px;

        @media (max-width: $xxl) {
            margin: 50px 116px;
        }

        @media (max-width: $lg) {
            margin: 50px 58px;
        }

        @media (max-width: $md) {
            margin: 0px 0px;
            row-gap: 6px;
            font-weight: bold;
        }

        .formStepWrapper {
            margin-top: 53px;
            
            @media (max-width: $md) {
                margin-top: 23px;
            }
        }

        .formStepWrapper0 {
            margin-top: 98px;
            
            @media (max-width: $md) {
                margin-top: 26px;
            }
        }
    }

    .updateProfileButton {
        display: flex;
        justify-content: center;

        @media (max-width: $md) {
            margin-top: 52px;
            margin-bottom: 65px;
        }
    }

    .InfoCardWrapper {
        border: red;
    }
}