@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.lettersWrapper {
  font-family: 'Univers';
  padding-top: 99px;
  padding-right: 210px;
  padding-left: 210px;
  padding-bottom: 260px;
  column-gap: 41px;

  @media (max-width: $xl) {
    padding-top: 51px;
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }

  @media (max-width: '632px') {
    padding-bottom: 472px;
  }

  @media (max-width: $sm) {
    padding-bottom: 458px;
  }

  @media (max-width: '406px') {
    padding-bottom: 503px;
  }

  .lettersHeader {
    display: flex;
    justify-content: space-between;

    @media (max-width: $md) {
      flex-direction: column;
      align-items: center;
      row-gap: 35px;
    }

    .lettersHeaderStart {
      display: flex;
      align-items: center;

      .lettersHeaderStartArrow {
        cursor: pointer;
        position: absolute;
        left: 117px;

        &:lang(ar) {
          right: 117px;
          left: inherit;
          transform: rotate(180deg);
        }

        img {
          width: 65px;
        }

        @media (max-width: $md) {
          left: 29px;

          &:lang(ar) {
            right: 29px;
          }

          img {
            width: 23px;
          }
        }
      }

      .lettersHeaderStartTitle {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.25;
        color: $color-primary;

        @media (max-width: $md) {
          font-size: 1.25rem;
        }

        &:lang(ar) {
          line-height: normal;
        }
      }

      @media (max-width: $xl) and (min-width: $md) {
        column-gap: 36px;

        .lettersHeaderStartArrow {
          position: static;
        }
      }
    }
  }

  .letters {
    margin-top: 36px;

    .lettersChildSenderWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .lettersChildSender {
        display: flex;
        column-gap: 30px;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.5;
        color: $color-primary;

        .lettersChildSenderName {
          display: flex;
          column-gap: 11px;

          span {
            color: $color-secondary-500;
          }
        }

        @media (max-width: $md) {
          column-gap: 18px;
        }
      }

      .lettersChildAttacment {
        display: flex;
        column-gap: 20px;

        @media (max-width: $lg) {
          row-gap: 16px;
          flex-direction: column-reverse;
          align-items: center;
          width: 100%;
        }

        @media (max-width: $md) {
          .lettersChildAttacmentFile {
            width: 100%;
          }
        }
      }

      @media (max-width: $lg) {
        align-items: start;
      }
    }

    .numberFilesLeft {
      font-size: 1.125rem;
      margin-top: 12px;
      text-align: end;
      color: $color-secondary-500;
    }

    .messagesContainer {
      border-radius: 20px;
      border: solid 1px $color-secondary-500;
      background-color: $color-white;
      overflow: hidden;
      position: relative;
      height: 485px;
      margin-top: 21px;

      .senderBlock,
      .reciverBlock {
        height: 100%;
        width: 4px;
        position: absolute;
      }

      .senderBlock {
        right: 132px;
        background: $color-secondary-500;

        @media (max-width: $md) {
          right: 66px;
        }

        @media (max-width: $xs) {
          right: 45px;
        }
      }

      .reciverBlock {
        left: 132px;
        background: $color-light-gray;

        @media (max-width: $md) {
          left: 66px;
        }

        @media (max-width: $xs) {
          left: 45px;
        }
      }

      .messages {
        overflow-y: scroll;
        height: 100%;
        margin: 0px 10px;
        padding: 40px 80px;

        // scrollbar
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: $color-gray;
          border-radius: 50px;
        }

        @media (max-width: $md) {
          margin: 0px 5px;
          padding: 0px 22px;
        }

        .message {
          @media (max-width: $md) {
            margin-top: 44px;
          }

          .messageInfo {
            display: flex;
            justify-content: space-between;
            font-size: 1.125rem;
            color: $color-secondary-500;

            @media (max-width: $md) {
              font-size: 0.875rem;
            }
          }

          .messageContent {
            border-radius: 20px;
            font-size: 1.125rem;
            position: relative;
            z-index: 2;

            @media (max-width: $md) {
              font-size: 0.875rem;
            }

            .messageContentAttachment {
              display: flex;
              align-items: center;
              padding: 28px 49px 13px;

              @media (max-width: $md) {
                padding: 28px 30px 13px;
              }

              column-gap: 32px;
              cursor: pointer;
            }

            .messageContentText {
              padding: 19px 49px 28px;

              @media (max-width: $md) {
                padding: 19px 30px 28px;
              }
            }

            .messageContentTextHasAttachment {
              border-top: 2px solid $color-white;
            }
          }

          .messageReceiver {
            .messageInfo {
              padding-left: 27px;
              padding-right: 56px;

              &:lang(ar) {
                padding-left: 56px;
                padding-right: 27px;
              }

              @media (max-width: $md) {
                padding-left: 6px;

                &:lang(ar) {
                  padding-right: 6px;
                }
              }

              @media (max-width: $xs) {
                padding-right: 30px;

                &:lang(ar) {
                  padding-left: 30px;
                }
              }
            }

            .messageContent {
              background-color: $color-secondary-500;
              color: $color-white;
            }
          }

          .messageSender {
            .messageInfo {
              padding-left: 56px;
              padding-right: 27px;

              &:lang(ar) {
                padding-left: 27px;
                padding-right: 56px;
              }

              @media (max-width: $md) {
                padding-right: 6px;

                &:lang(ar) {
                  padding-left: 6px;
                }
              }

              @media (max-width: $md) {
                padding-left: 30px;

                &:lang(ar) {
                  padding-right: 30px;
                }
              }
            }

            .messageContent {
              background-color: $color-light-gray;
              color: $color-primary-500;
            }
          }
        }

        .messageReceiverWrapper {
          display: flex;
          justify-content: end;
        }

        .messageSenderWrapper {
          display: flex;
          justify-content: start;
        }

        .messageSender,
        .messageReceiver {
          width: 40%;

          @media (max-width: $md) {
            width: 75%;
          }
        }
      }
    }

    @media (max-width: $lg) {
      margin-top: 30px;

      .lettersChildSenderWrapper {
        flex-direction: column-reverse;

        .lettersChildSender {
          margin-top: 28px;
        }
      }
    }

    @media (max-width: $md) {
      .lettersChildSenderWrapper {
        .lettersChildSender {
          font-size: 0.875rem;
        }
      }
    }

    .letterText {
      margin-top: 24px;
      position: relative;

      @media (max-width: $md) {
        margin-top: 10px;
      }

      .triangleGray {
        position: absolute;
        width: 0;
        height: 0;
        bottom: 5px;
        right: 5px;
        border-style: solid;
        border-width: 0 0 25px 25px;
        border-color: transparent transparent $color-secondary-500 transparent;
      }
      .triangleGrayError {
        bottom: 42px;
        right: 8px;
      }
    }

    .letterSend {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      @media (max-width: $md) {
        margin-top: 32px;
      }
    }
  }
}

.messagesContainerWebEmpty {
  margin: 98px 0px;
}

.emptyLetters {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -32px;
  text-align: center;
  padding: 0px 10px;
}
