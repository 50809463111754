@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-select {
  width: 100%;

  .ant-select-selector {
    padding: 19px 50px !important;
    border-radius: 10px !important;
    border: solid 1px #063060 !important;
    height: 100% !important;
    font-size: 1.25rem;

    .ant-select-selection-item {
      text-transform: capitalize;
      color: $color-primary;

      font-weight: normal;
      display: flex;
      align-items: center;
    }

    @media (max-width: $md) {
      font-size: 1rem;
    }

    @media (max-width: $xl) {
      padding: 15px 25px !important;
    }
  }

  @media (max-width: $lg) {
    width: 100%;
  }

  .ant-select-arrow {
    display: none;
  }
}

.ant-select-dropdown {
  border-radius: 10px;
  padding: 0;

  &:focus {
    box-shadow: none;
  }

  .ant-select-item-option {
    padding: 20px 20px 20px 40px;
    font-size: 1.25rem;
    font-weight: bold;
    background-color: $color-white;
    color: $color-primary;
    opacity: 0.6;
    text-transform: capitalize;

    @media (max-width: $lg) {
      padding: 15px 15px 15px 50px;
      font-size: 1rem;
    }

    @media (max-width: $md) {
      padding: 15px 15px 15px 15px;
      font-size: 1rem;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: $color-primary;
    color: $color-white;
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: $color-primary-500 !important;
  box-shadow: none !important;
}

.ant-select-selector {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%2397a3ae" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>')
    no-repeat right #ddd;

  background-position: right 3rem center;

  &:lang(ar) {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%2397a3ae" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>')
      no-repeat right #ddd;

    background-position: left 3rem center;
  }

  @media (max-width: $xxl) {
    background-position: right 1rem center;

    &:lang(ar) {
      background-position: left 1rem center;
    }
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%2397a3ae" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>')
    no-repeat right #ddd;

  background-position: right 3rem center;

  &:lang(ar) {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%2397a3ae" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>')
      no-repeat right #ddd;

    background-position: left 3rem center;
  }

  @media (max-width: $xxl) {
    background-position: right 1rem center;

    &:lang(ar) {
      background-position: left 1rem center;
    }
  }
}

.phoneNumberCountry {
  .ant-select-selector {
    background-color: $color-background !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    padding: 20.5px 20px !important;
    background-position: right 1.5rem center;
    border-color: $color-secondary-500 !important;
    border-right: none !important;

    @media (max-width: $lg) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }

    &:lang(ar) {
      background-position: left 1.5rem center;
      border-left: none !important;
      border-right: solid 1px #063060 !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }

  // Hide country name in selected country
  .ant-select-selection-item .countryCodeSelectName {
    display: none;
  }
}

.ant-select-selection-search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 38px !important;
  @media (max-width: $md) {
    padding: 19px 15px !important;
  }
}

.ant-form-item-has-error {
  .ant-select-selector {
    border: 2px solid $color-error !important;
    border-right: none !important;
  }
}

.phoneNumberMask,
.phoneNumberCountry {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.freqSelect {
  .ant-select-selector {
    background: url('../assets/images/arrow-dark.svg') no-repeat right $color-white;
    background-size: 8px;

    &:lang(ar) {
      background: url('../assets/images/arrow-dark-rtl.svg') no-repeat right $color-white;
    }

    @media (min-width: $md) {
      .ant-select-selection-item {
        padding-left: 12px;

        &:lang(ar) {
          padding-left: inherit;
          padding-right: 12px;
        }
      }
    }
  }
}
