@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.donationsCardWrapper {
  margin-top: 30px;
  .donationsCard {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .card {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      border-radius: 15px;
      background-color: $color-white;
      padding: 26px 35px;
      @media (max-width:$xxs) {
        padding: 26px 15px;
      }
      .cardRow {
        display: flex;
        div {
          width: 100%;
          align-items: center;
          font-size: 0.875rem;
          font-weight: normal;
        }
        .cardHeader {
          color: $color-secondary-500;
        }
        .cardContent {
          color: $color-primary;
        }
      }
    }
  }
}
