@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.dashboardHeaderWrapper {
    .dashboardHeader {
        display: flex;
        justify-content: space-between;

        @media (max-width: $lg) {
            flex-direction: column;
        }

        .dashboardHeaderWelcome {
            .dashboardHeaderWelcomeHeader {
                font-size: 2.5rem;
                font-weight: bold;
                line-height: 1.25;
                color: $color-primary;

                span {
                    font-family: Univers;
                }
                
                @media (max-width: $md) {
                    font-size: 1.25rem;
                }
            }

            .dashboardHeaderWelcomeSubHeader {
                font-size: 1rem;
                line-height: 1.56;
                letter-spacing: 1.6px;
                color: #aeb8bf;
                margin-top: 13px;
                text-transform: uppercase;
                
                @media (max-width: $md) {
                    font-size: 0.75rem;
                }

                &:lang(ar) {
                    letter-spacing: normal;
                }

                img {
                    margin-top: -5px;
                    margin-right: 15px;
                    &:lang(ar) {
                        margin-right: 0px;
                        margin-left: 15px;
                    }
                }

                span:first-child {
                    margin-left: 22px;
                    &:lang(ar) {
                        margin-left: 0px;
                        margin-right: 22px;
                    }
                }

                span {
                    font-size: 1.125rem;
                    font-weight: bold;
                    line-height: 1.67;
                    color: $color-black;
                
                    @media (max-width: $md) {
                        font-size: 0.875rem;
                    }
                }
            }

        }
        .dashboardHeaderBalance {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 66%;
            background-color: $color-primary;
            color: $color-white;
            border-radius: 20px;
            min-height: 96px;
            
            @media (max-width: $lg) {
                width: 100%;
                margin-top: 39px;
            }
            
            @media (max-width: $md) {
                flex-direction: column;
                padding: 29px 0px;
            }

            .dashboardHeaderBalanceItem {
                display: flex;
                column-gap: 10px;
                min-width: 150px;

                img {
                    width: 32px;
                }

                div {
                    .dashboardHeaderBalanceItemHeader {
                        opacity: 0.4;
                        font-size: 0.75rem;

                        @media (max-width: $md) {
                            font-size: 10px;
                        }
                    }
                    p {
                        margin: 0px;
                        font-size: 1.125rem;
                        font-weight: bold;
                        line-height: 1.67;
                        color: $color-white;

                        @media (max-width: $md) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }
}

.divider {
    height: 50px;
    width: 1px;
    background: rgba($color: $color-background, $alpha: 0.6);

    @media (max-width: $md) {
        height: 1px;
        width: 150px;
        margin: 28px 0px;
    }
}