@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.alertWrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(3, 47, 97, 0.16);
  background-color: $color-background;
  border: 1px solid $color-background;
  margin-top: 33px;
  position: relative;
  .alertValue {
    font-family: 'Univers';
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: start;
    padding: 10px 33px;
    white-space: break-spaces;
    line-height: 1.5;
    
    @media (max-width: $sm) {
      font-size: 0.75rem;
    }
  }
  .alertValue:first-letter {
    text-transform: capitalize;
  }
  .alertIcon {
    width: 23px;
    height: 100%;
    position: absolute;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    &:lang(ar) {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  @media (max-width: $md) {
    margin-top: 15px;
  }
  @media (max-width: $xs) {
    font-size: 0.75rem;
    .alertIcon {
      width: 13px;
      height: 34px;
    }
  }
}

.alert-danger {
  .alertValue {
    color: $color-error;
  }
  .alertIcon {
    background: $color-error;
    opacity: 0.4;
  }
}

.alert-info {
  .alertValue {
    color: $color-primary-500;
  }
  .alertIcon {
    background: $color-primary-500;
  }
}

.alert-success {
  .alertValue {
    color: $color-success;
  }
  .alertIcon {
    background: $color-success;
  }
}
