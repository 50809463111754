@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.donationsCardWrapper {
  .donationsCard {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .card {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      border-radius: 15px;
      background-color: $color-white;
      padding: 26px 54px;
      @media (max-width:$xxs) {
        padding: 26px 15px;
      }
      .cardRow {
        display: flex;
        justify-content: center;
        .cardHeader,
        .cardContent {
          width: 50%;
          font-size: 0.875rem;
          font-weight: normal;
          text-align: start;
          margin-top: 11px;
        }
        img {
          border-radius: 9px;
        }
        .cardHeader {
          color: $color-secondary-500;
        }
        .cardContent {
          color: $color-primary;
          padding-left: 23px;
        }
        .cardImageRow {
          display: flex;
          justify-content: center;
        }
        .cardIdNameRow {
          @media (max-width: $md) {
            margin-top: 26px;
          }
        }
        .cardActionRow {
          @media (max-width: $md) {
            margin-top: 11px
          }
        }

        .cardActionRow,
        .cardActionRow div,
        .cardIdNameRow,
        .cardIdNameRow div {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .cardActionRow,
        .cardActionRow div,
        .cardIdNameRow {
          display: flex;
          justify-content: center;
          width: fit-content;
          column-gap: 20px;
        }
      }

      .cardRowDataName {
        margin-bottom: 14px;
      }

      .cardRowData {
        margin-bottom: 9px;
      }
    }
  }
}
