@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.progressStepWrapper {
    padding: 55px 0px 60px;
    .progressStep {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        column-gap: 10px;
        border-bottom: 1px solid $color-secondary-500;

        .singleStep {
            cursor: pointer;
            .singleStepNum {
                border-radius: 10px;
                border: solid 1px $color-secondary-500;
                color: $color-secondary-500;
                width: 80px;
                padding: 11px 0px 7px;
                margin: 0px auto;
                font-family: Univers;
                font-weight: bold;
                font-size: 1.25rem;

                @media (max-width: $sm) {
                    width: 54px;
                    padding: 11px 0px 7px;
                    font-size: 0.75rem;
                }
                @media (max-width: $xxs) {
                    margin-bottom: 20px;
                    width: 43px;
                }

            }
            
            .singleStepName {
                margin: 20px 0px 28px;
                color: $color-secondary-500;
                font-family: Univers;
                letter-spacing: 1.6px;
                text-transform: uppercase;
                font-size: 1rem;
                
                @media (max-width: $sm) {
                    letter-spacing: 0.5px;
                    font-size: 10px;
                }
                @media (max-width: $xxs) {
                    display: none;
                }

                &:lang(ar) {
                    letter-spacing: normal;
                }

            }
        }

        .singleStepActive {
            .singleStepNum {
                background-color: $color-primary;
                color: $color-white;
            }
            
            .singleStepName {
                color: $color-primary;
            }
        }
    }
}