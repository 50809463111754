@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.GiftSection {
  .giftSectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.88;
      letter-spacing: 1.6px;
      color: $color-black;
      text-transform: uppercase;
      margin-bottom: 0px;

      &:lang(ar) {
        letter-spacing: normal;
      }

      @media (max-width: $md) {
        font-size: 0.75rem;
      }
    }

    .freqSelect {
      width: 186px;
      @media (max-width: $md) {
        width: 132px;
      }
    }
  }

  .giftRowWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 25px;

    .giftRow {
      display: flex;
      justify-content: space-between;
      border-radius: 15px;
      overflow: hidden;
      background-color: $color-white;

      @media (max-width: $md) {
        flex-direction: column;
      }

      .giftRowContent {
        padding: 32px 37px;
        width: 100%;

        @media (max-width: $sm) {
          padding: 32px 26px;
        }

        .giftRowContentRow {
          row-gap: 23px !important;

          .giftRowContentCol {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 23px;

            .cellLabelCol {
              display: flex;
              align-items: center;
              column-gap: 5px;
              .cellLabel {
                font-size: 1rem;
                letter-spacing: 1.6px;
                color: $color-secondary-500;
                text-transform: uppercase;

                &:lang(ar) {
                  letter-spacing: normal;
                }

                @media (max-width: $md) {
                  font-size: 0.75rem;
                }

                .cellLabelBehalf {
                  display: flex;
                  align-items: center;
                }

                .cellLabelOptional {
                  opacity: 0.6;
                  font-size: 0.875rem;
                  font-weight: bold;
                  line-height: 2.14;
                  letter-spacing: 1.4px;
                  color: $color-primary;

                  &:lang(ar) {
                    letter-spacing: normal;
                  }
                }
              }
            }

            .cellLabelColBehalf {
              align-items: flex-start;
              .cellLabelColBehalfToolTip {
                position: absolute;
                left: -23px;

                &:lang(ar) {
                  right: -23px;
                  left: inherit;
                }
              }
            }

            .cellValue {
              font-size: 1.125rem;
              font-weight: bold;
              color: $color-primary;

              @media (max-width: $md) {
                font-size: 0.875rem;
              }
            }

            .cellValueName {
              padding-right: 20px;
              display: flex;
              column-gap: 18px;
              @media (max-width: $xl) {
                column-gap: 10px;
              }
              @media (max-width: $sm) {
                column-gap: 5px;
              }
              &:lang(ar) {
                padding-left: 14px;
                padding-right: 0px;
              }
            }
          }
        }
      }

      .giftRowDelete {
        background-color: $color-primary;
        padding: 50px 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-width: $md) {
          padding: 13px 32px;
        }
      }
    }
  }

  .giftSectionButton {
    margin-top: 36px;
    font-size: 1rem;
    @media (max-width: $md) {
      margin: 29px 0px;
      display: flex;
      justify-content: center;
      font-size: 0.75rem;
    }
  }
}

.errorWrapper {
  margin-bottom: 48px;
}
