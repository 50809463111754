@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.singleProjectWrapper {
  padding-bottom: 250px;
  @media (max-width: $lg) {
    padding-bottom: 480px;
  }
  @media (max-width: $xs) {
    padding-bottom: 460px;
  }
  @media (max-width: '375px') {
    padding-bottom: 490px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }
  .projectTitleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-background;
    padding: 30px 0 42px;
    .projectTitle {
      font-family: 'Univers';
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: normal;
      color: $color-primary;
      padding: 0 50px;
      text-transform: capitalize;
      overflow-wrap: break-word;
      word-wrap: break-word;
      text-align: center;
    }
    .projectBtn {
      margin-top: 40px;
    }
    @media (max-width: $md) {
      padding: 66px 0 35px;
      .projectTitle {
        font-size: 1.25rem;
      }
    }
  }
  .projectContentWrapper {
    display: flex;
    column-gap: 40px;
    padding: 60px 116px 0;
    @media (max-width: $xxl) {
      padding: 100px 58px 0;
    }
    @media (max-width: $xl) {
      column-gap: 20px;
    }
    @media (max-width: $lg) {
      padding: 100px 38px 0;
    }
    @media (max-width: $md) {
      padding: 32px 0 0;
    }
    .projectImages {
      width: 45%;
      margin: 0 -20px;

      .projectImage {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 400px;
        overflow: hidden;
        background-color: $color-background;
        border-radius: 26px;
        @media (max-width: $sm) {
          height: 300px;
        }
        @media (max-width: $xs) {
          height: 200px;
        }

        img {
          width: 100%;
          position: absolute;
        }
      }

      @media (max-width: $md) {
        padding: 0 8px;
        img {
          border-radius: 15px;
        }
      }
      @media (max-width: $sm) {
        padding: 0;
      }
    }
    .projectDesc {
      display: flex;
      align-items: center;
      width: 55%;
      font-size: 1.3125rem;
      line-height: 1.43;
      @media (max-width: $md) {
        padding: 0 28px;
        font-size: 1.125rem;
        line-height: 1.67;
      }
    }
    @media (max-width: $lg) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 60px;
      .projectImages {
        width: 100%;
      }
      .projectDesc {
        width: 100%;
      }
    }
  }
  @media (max-width: $md) {
    margin: -30px;
  }
  &:lang(ar) {
    font-family: NotoSansArabic;
  }
}
