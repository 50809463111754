@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.childrenWrapper {
  padding-bottom: 280px;
  @media (max-width: $lg) {
    padding-bottom: 500px;
  }
  @media (max-width: $md) {
    padding-bottom: 452px;
  }
  @media (max-width: $sm) {
    padding-bottom: 464px;
  }
  @media (max-width: '375px') {
    padding-bottom: 500px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 517px;
  }
  .childrenListWrapper {
    .childrenFiltersDrawer {
      border-radius: 15px;
      margin: 30px 58px;
      @media (max-width: $md) {
        background-color: $color-background !important;
        margin: 0;
        border-radius: 0;
      }
    }
    .childrenFilters {
      .filter {
        width: 20%;
        @media (max-width: $lg) {
          width: 100%;
        }
      }
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      background-color: $color-white;
      padding: 50px 116px 0;
      @media (max-width: $xxl) {
        padding: 50px 58px 0;
        column-gap: 10px;
      }
      @media (max-width: $lg) {
        display: block;
      }
      @media (max-width: $md) {
        padding: 50px 29px;
      }
      @media (max-width: $sm) {
        padding: 20px 28px;
      }
      .filterDrawerWrapper {
        @media (max-width: $md) {
          background-color: $color-white;
          border-radius: 15px;
          padding: 30px 58px;
          @media (max-width: $sm) {
            padding: 20px 28px;
          }
        }
      }
      .filterDrawer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        column-gap: 20px;
        .filterOPenIcon {
          cursor: pointer;
        }
        .filterDrawerTitle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 30px;
          p {
            margin-bottom: 0;
            font-family: 'Univers';
            font-size: 1.75rem;
            font-weight: bold;
            text-transform: capitalize;
            color: $color-primary;
            @media (max-width: $sm) {
              font-size: 1.125rem;
            }
          }
        }
        .filterCloseIcon {
          padding-top: 30px;
        }
      }
    }
    .filterDrawerContent {
      display: flex;
      flex-direction: column;
      row-gap: 50px;
      padding: 80px 8px 34px;
      p {
        font-family: 'Univers';
        font-size: 1.5625rem;
        font-weight: normal;
        color: $color-primary;
        @media (max-width: $sm) {
          font-size: 1rem;
        }
      }
    }
    .childrenListCards {
      padding: 70px 116px 0;

      @media (max-width: $xxl) {
        padding: 70px 58px 0;
      }

      @media (max-width: $md) {
        padding: 0px;
        margin-top: 0px;
      }
      .empty{
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .childrenList {
      position: relative;

      .childrenListSubTitle {
        display: flex;
        justify-content: space-between;

        .childrenListSubTitleCode {
          font-family: 'Univers';
        }
      }

      .clearFiltersSearch {
        display: flex;
        justify-content: space-around;
        column-gap: 20px;
        
        @media (max-width: $lg) {
          margin-top: 70px;
        }

        .clearFilters {
          cursor: pointer;
          display: flex;
          border: 1px solid $color-primary;
          padding: 0px 10px;
          border-radius: 8px;
        }

        .searchBtn {
          @media (max-width: $lg) {
            display: flex;
            justify-content: center;
          }
          .icon {
            position: absolute;
            top: 205px;
            right: 80px;
            @media (max-width: $xxl) {
              right: 15px;
            }
            @media (max-width: $lg) {
              top: auto;
              right: auto;
              margin-left: 230px;
              margin-top: -40px;
            }
  
            @media (max-width: $xs) {
              display: none;
            }
            &:lang(ar) {
              img {
                transform: rotateY(180deg);
              }
              right: auto;
              left: 75px;
              @media (max-width: $xxl) {
                left: 15px;
              }
              @media (max-width: $lg) {
                top: auto;
                left: auto;
                margin-left: -230px;
                margin-top: -40px;
              }
              @media (max-width: '375px') {
                margin-left: -170px;
              }
            }
          }
        }
      }
      .childrenListCards {
        background-color: $color-white;
        padding-top: 70px;

        @media (max-width: $md) {
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 32px;
        }
      }

      .childrenPagination {
        display: flex;
        justify-content: center;
        margin-top: 66px;
        padding-bottom: 10px;
      }
    }
    @media (max-width: $md) {
      margin: -30px -30px 0;
    }
  }
  .testimoniesWrapper {
    @media (max-width: $md) {
      display: none;
    }
    .testimoniesHeader {
      background-color: $color-white;
    }
    .testimoniesContent {
      width: 100%;
      justify-content: space-between;
      text-align: center;
      font-family: 'Univers';
      font-weight: normal;
      padding: 80px 130px 0;

      @media (max-width: $xxl) {
        padding: 80px 88px 0;
      }
      @media (max-width: $xl) {
        justify-content: center;
        row-gap: 80px !important;
      }
      .testimoniesItem {
        @media (max-width: $xl) {
          padding: 20px;
        }
      }
      .testimonyImage {
        img {
          max-width: 100%;
        }
      }
      .testimonyName {
        font-size: 1.25rem;
        margin-top: 16px;
      }
      .testimonyPosition {
        font-size: 1rem;
      }
      .testimonyFeedback {
        font-size: 1.25rem;
        margin-top: 30px;
      }
    }
  }
}
