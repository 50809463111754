@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.FAQsWrapper {
  background-color: $color-background;
  @media (max-width: $md) {
    margin: -30px;
    padding: 30px;
  }
  .FAQsCollapse {
    padding: 50px 116px 0;
    @media (max-width: $xxl) {
      padding: 10px 58px 0;
    }
    @media (max-width: $md) {
      padding: 10px 0 0;
    }
    .FAQsCollapseItem {
      background-color: $color-white;
      margin-bottom: 20px;
      padding: 50px 70px 50px 140px;
      border-radius: 15px !important;
      &:lang(ar) {
        padding: 50px 140px 50px 70px;
      }
      @media (max-width: $xxl) {
        padding: 30px 70px 30px 140px;
      }
      .antDivider {
        margin: 18px 0 35px;
        @media (max-width: $xxl) {
          margin: 0 0 24px;
        }
        border-top: 1px solid $color-offwhite;
      }
      .FAQsAnswer {
        p pre {
          white-space: normal;
          overflow: hidden;
        }
      }
    }
    .FAQsCollapseItem,
    .FAQsCollapseItem:lang(ar) {
      @media (max-width: $xxl) {
        padding: 30px 75px;
      }
      @media (max-width: $lg) {
        padding: 25px 25px;
      }
      @media (max-width: $sm) {
        padding: 25px 10px;
      }
      .FAQsCollapseItem,
      .FAQsCollapseItem:lang(ar) {
        @media (max-width: $xxl) {
          padding: 30px 75px;
        }
        @media (max-width: $lg) {
          padding: 25px 25px;
        }
        @media (max-width: $sm) {
          padding: 20px 10px;
        }
      }
    }
  }
  .FAQsPagination {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 270px;

    @media (max-width: $lg) {
      padding-bottom: 485px;
    }
    @media (max-width: $md) {
      padding-bottom: 460px;
    }
    @media (max-width: $xs) {
      padding-bottom: 440px;
    }
    @media (max-width: '375px') {
      padding-bottom: 480px;
    }
    @media (max-width: $xxs) {
      padding-bottom: 490px;
    }
  }
}
