@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.phoneNumberWrapper {
    display: flex;
    width: 100%;

    .phoneNumberCountry {
        width: 20%;
    }

    .phoneNumberMask {
        position: relative;
        width: 80%;
    }

    @media (max-width: $xxl) {
        .phoneNumberCountry {
            width: 30%;
        }
    
        .phoneNumberMask {
            width: 70%;
        } 
    }
    
    @media (max-width: $xl) {
        .phoneNumberCountry {
            width: 30%;
        }
    
        .phoneNumberMask {
            width: 70%;
        } 
    }
    
    @media (max-width: $xs) {
        .phoneNumberCountry {
            width: 40%;
        }
    
        .phoneNumberMask {
            width: 60%;
        } 
    }
}