@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.forgetPasswordWrapper {
  display: flex;
  justify-content: space-between;
  font-family: 'Univers';
  padding-top: 81px;
  padding-right: 116px;
  padding-left: 116px;
  padding-bottom: 267px;
  column-gap: 41px;
  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 458px;
  }
  @media (max-width: '375px') {
    padding-bottom: 497px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }
  .forgetPasswordImage {
    width: 32%;
    background-image: url('/assets/images/forget-password-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: solid 1px $color-secondary-500;
    border-radius: 20px;
  }
  .forgetPasswordForm {
    width: 68%;
    .formWrapper {
      .form {
        padding: 49px 90px 26px;
        .errorMessage {
          margin-bottom: 0;
          margin-top: -30px;
          color: $color-error;
        }
        .forgetPasswordText {
          font-size: 1.25rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $color-primary;
          padding: 0 100px;
          margin-bottom: 42px;
          p {
            margin-bottom: 0;
          }
        }
        .sendBtn {
          display: flex;
          justify-content: center;
          margin-top: 26px;
        }
      }
      @media (max-width: $xxl) {
        .form {
          padding: 49px 30px 26px;
          .forgetPasswordText {
            padding: 0 20px;
          }
        }
      }
      @media (max-width: $md) {
        .form {
          padding: 16px 0 10px;
          .forgetPasswordText {
            margin-bottom: 35px;
          }
          .sendBtn {
            margin-top: 8px;
          }
        }
      }
      @media (max-width: $sm) {
        .form {
          .forgetPasswordText {
            padding: 0;
            font-size: 1rem;
          }
        }
      }
    }
    .emailWrapper {
      padding: 99px 0 116px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .emailText {
        font-size: 1.25rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $color-primary;
        padding: 0 180px;
        p {
          margin-bottom: 0;
        }
      }
      .email {
        padding: 40px 0 45px;
        font-size: 1.5625rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $color-primary;
        p {
          margin-bottom: 0;
        }
      }
      @media (max-width: $xxl) {
        .emailText {
          padding: 0 20px;
        }
      }
      @media (max-width: $md) {
        padding: 16px 0 29px;
        .emailText {
          font-size: 1rem;
          @media (max-width: $sm) {
            padding: 0;
          }
        }
        .email {
          font-size: 1.125rem;
          padding: 15px 0 17px;
          @media (max-width: $xxs) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: $lg) {
    .forgetPasswordImage {
      display: none;
    }
    .forgetPasswordForm {
      width: 100%;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
