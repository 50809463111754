@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.tabsSectionTitle {
    margin-bottom: -55px;

    @media (max-width: $md) {
        text-align: start;
        margin-bottom: -152px;
        margin-top: 46px;
    }
}