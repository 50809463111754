@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.pagination {
  &:lang(ar) {
    .arrows {
      transform: rotateY(180deg);
    }
  }
  ul {
    display: flex;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px !important;
      margin: 0px 11px !important;
      font-size: 1.125rem;
      border: 1px solid $color-primary !important;
      border-radius: 50%;
      @media (max-width:$xs) {
        width: 32px;
        height: 32px !important;
        margin: 0px 6px !important;
      }
    }
  }
}
