@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.sponsorshipsWrapper {
  font-family: 'Univers';
  padding-top: 105px;
  padding-right: 210px;
  padding-left: 210px;
  padding-bottom: 260px;
  column-gap: 41px;

  @media (max-width: $xxl) {
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $lg) {
    padding-top: 51px;
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 0px;
    padding-right: 29px;
    padding-left: 29px;
    padding-bottom: 68px;
  }

  @media (max-width: '632px') {
    padding-bottom: 472px;
  }

  @media (max-width: $sm) {
    padding-bottom: 507px;
  }

  @media (max-width: '406px') {
    padding-bottom: 510px;
  }
  .sponsorshipsTitle {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: normal;
    color: $color-primary;
    @media (max-width: 1400px) {
      font-size: 1.875rem;
    }
    @media (max-width: $lg) {
      font-size: 1.375rem;
    }
    @media (max-width: $md) {
      font-size: 1.25rem;
      text-align: center;
      line-height: 2.5;
      padding-top: 44px;
    }
  }

  .sponsorshipsPagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    
    @media (max-width: $md) {
      margin-top: 79px;
    }
  }

  .childImage {
    width: 68px;
    height: 95px;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }

  .childIdName {
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    color: $color-primary;
    direction: ltr;
    
    span {
      font-family: 'Univers';
      color: $color-secondary-500;
      margin: 0px 12px;
    }
    
    @media (max-width: $lg) {
      font-size: 0.875rem;
    }
  }
  
  .childPrice {
    font-size: 1rem;
    font-weight: normal;
    span {
      font-size: 1.125rem;
      font-weight: bold;
    }

    @media (max-width: $md) {
      font-size: 0.75rem;
      font-weight: normal;
      span {
        font-size: 0.875rem;
        font-weight: bold;
      }
    }
  }

  .date {
    font-weight: normal;
    font-size: 1rem;
    @media (max-width: $md) {
      font-size: 0.875rem;
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 12px;

    div {
      cursor: pointer;

      img {
        @media (max-width: $md) {
          width: 28px;
        }
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
