@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.heroSection {
  font-family: Univers;

  @media (max-width: $md) {
    margin: -30px;
  }

  .hero {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    .heroBG {
      max-height: 100vh;
      min-height: 828px;
      @media (min-width: '1920px') {
        img {
          min-width: 100vw;
        }
      }
      @media (max-width: $xl) {
        margin-left: -500px;
      }

      @media (max-width: $md) {
        margin-top: -100px;
      }
      @media (max-width: $xxs) {
        margin-top: 0px;
      }
    }

    .heroContent {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      padding-right: 116px;
      padding-left: 116px;
      bottom: 0px;

      @media (max-width: $xxl) {
        padding-right: 58px;
        padding-left: 58px;
      }

      @media (max-width: $md) {
        padding-right: 29px;
        padding-left: 29px;
      }

      .heroContentText {
        .heroContentTextHeader {
          font-family: 'Univers Bold Condensed';
          font-weight: bold !important;
          text-transform: uppercase;

          h1 {
            line-height: normal;
            font-size: 5rem;
            color: $color-white;
            margin: 0px;
            @media (max-width: $xxl) {
              font-size: 4.375rem;
            }
            @media (max-width: $md) {
              font-size: 2.5rem;
              padding: 0 2px;
            }
            &:lang(ar) {
              line-height: 1.25;
            }
          }
        }

        .heroContentTextPar {
          h3 {
            color: $color-white;
            margin: 0px;
            font-size: 2.625rem;
            @media (max-width: $lg) {
              font-size: 24px;
            }
          }
        }
      }

      .heroContentChild {
        padding: 44px;
        margin: 70px 0 50px;
        background: rgba(255, 255, 255, 0.48);
        border-radius: 20px;
        backdrop-filter: blur(7.9px);
        -webkit-backdrop-filter: blur(7.9px);
        @media (max-width: $xl) {
          padding: 35px 20px 55px;
        }
        @media (max-width: $md) {
          margin: 30px 0 40px;
        }
        @media (max-width: $sm) {
          padding: 25px 20px 15px;
        }

        .heroContentChildHeader {
          h6 {
            font-family: 'Univers Bold';
            font-weight: bold;
            font-size: 2.5rem;
            color: $color-primary;
            margin-bottom: 0;
            @media (max-width: $xl) {
              font-size: 1.875rem;
              text-align: center;
            }
            @media (max-width: $xs) {
              font-size: 1.5625rem;
            }
          }
        }

        .heroContentChildFilter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // @media (max-width: $xxl) {
          //   column-gap: 15px;
          // }
          @media (max-width: $xl) {
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
          }
          @media (max-width: $xs) {
            row-gap: 14px;
          }

          .heroContentChildFilterPara {
            display: flex;
            align-items: center;
            p {
              margin: 0px;
              font-size: 1.25rem;
              @media (max-width: $xxl) {
                font-size: 1.125rem;
              }
              @media (max-width: $xl) {
                text-align: center;
              }
              @media (max-width: $xs) {
                font-size: 1rem;
              }
            }
            span {
              margin-bottom: -5px;
            }
          }

          .heroContentChildFilterOptions {
            display: flex;
            column-gap: 30px;
            @media (max-width: $xxl) {
              column-gap: 15px;
            }
            @media (max-width: $xl) {
              column-gap: 35px;
            }
            @media (max-width: $xs) {
              column-gap: 8px;
            }
            @media (max-width: $xxs) {
              flex-direction: column;
              row-gap: 8px;
              column-gap: 0px;
              align-items: center;
            }
          }
          .heroContentChildFilterOptions:last-child {
            padding-left: 100px;
            &:lang(ar) {
              padding-left: 0;
              padding-right: 100px;
              @media (max-width: $xxl) {
                padding-right: 0;
              }
            }
            @media (max-width: $xxl) {
              padding-left: 0;
              padding-right: 0;
            }
          }
          .heroContentChildLevel {
            column-gap: 60px;
            @media (max-width: $xxl) {
              column-gap: 30px;
            }
            @media (max-width: $xl) {
              flex-direction: column;
              row-gap: 20px;
              align-items: center;
            }
            @media (max-width: $xl) {
              display: flex;
              justify-content: space-between;
              column-gap: 50px;
            }
            @media (max-width: $xs) {
              column-gap: 8px;
            }
            @media (max-width: $xxs) {
              column-gap: 14px;
            }

            .heroContentChildLevelBtns {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: 30px;
              @media (max-width: $xxl) {
                column-gap: 15px;
              }
              @media (max-width: $xl) {
                column-gap: 50px;
              }
              @media (max-width: $xs) {
                column-gap: 8px;
              }
              @media (max-width: $xxs) {
                flex-direction: column;
                row-gap: 8px;
              }
            }
          }
        }
      }
    }
  }
}

html:lang(ar) {
  .heroSection {
    font-family: 'NotoSansArabic';
  }

  .heroContentTextHeader {
    font-family: 'NotoSansArabic Bold' !important;
  }
}
