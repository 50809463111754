@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.toolTip {
    font-family: Univers;

    .toolTipIcon {
        display: flex;
        img {
            width: 18px;
        }
    }

    .toolTipData {
        display: none;
        position: absolute;
        padding: 15px 6px;
        margin-top: 11px;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(3, 47, 97, 0.16);
        background-color: $color-background;
        align-items: center;
        padding-left: 0px;
        overflow: hidden;
        z-index: 2;
        
        &:lang(ar) {
            padding-left: 6px;
            padding-right: 0px;
        }

        .toolTipDataBlock {
            position: absolute;
            width: 23px;
            height: 100%;
            background-color: rgba($color: $color-primary, $alpha: 0.6);
        }

        p {
            opacity: 0.6;
            margin-bottom: 0px;
            font-size: 1rem;
            color: $color-primary;
            padding: 0px 35px;
            padding-right: 15px;
            white-space: nowrap;
            
            &:lang(ar) {
                padding-left: 15px;
                padding-right: 35px;
            }
        }
    }

    &:hover {
        .toolTipData {
            display: flex;
        }
    }
}