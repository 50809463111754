.ant-image {
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    max-width: 120px;
    cursor: pointer;   
    display: flex;
    justify-content: center;
    align-items: center;
}
