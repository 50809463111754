@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-collapse {
  background-color: transparent;
  border: none;
  .ant-collapse-item {
    .ant-collapse-header,
    .ant-collapse-content {
      color: $color-primary !important;
      font-family: 'Univers';
      border: none;
      &:lang(ar),
      pre {
        font-family: 'NotoSansArabic';
      }
    }
    .ant-collapse-header {
      flex-direction: row-reverse;
      justify-content: space-between;
      column-gap: 15px;
      letter-spacing: normal;
      font-weight: bold;
      font-size: 1.25rem;
      padding: 5px 16px;
      border: none;
      @media (max-width: $xxl) {
        font-size: 1.125rem;
      }
      @media (max-width: $sm) {
        font-size: 1rem;
        align-items: center;
      }
      &:lang(ar) {
        padding: 5px 16px;
      }
    }
    .ant-collapse-content {
      font-size: 1.125rem;
      font-weight: normal;
      @media (max-width: $xxl) {
        font-size: 1rem;
      }
      @media (max-width: $sm) {
        font-size: 0.875rem;
      }
      .ant-collapse-content-box {
        padding-bottom: 0;
        p {
          margin-bottom: 0;
          max-width: 90%;
          @media (max-width: $sm) {
            max-width: 100%;
          }
        }
      }
    }
  }

  .ant-collapse-arrow {
    font-size: 16px !important;
    margin-right: 0 !important;
  }
  .ant-collapse-item-active {
    border: 1px solid $color-secondary-500;
  }
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: relative;
}
