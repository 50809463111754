@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

@font-face {
  font-family: 'Univers';
  src: local('Univers'), url(./fonts/Univers/UniversLTStd.otf) format('woff');
}
@font-face {
  font-family: 'Univers Light';
  src: local('Univers'), url(./fonts/Univers/UniversLight.ttf) format('woff');
}
@font-face {
  font-family: 'Univers Bold';
  font-weight: bold;
  src: local('Univers'), url(./fonts/Univers/UniversLTStd-Bold.otf) format('woff');
}
@font-face {
  font-family: 'Univers Bold Condensed';
  font-weight: bold;
  src: local('Univers'), url(./fonts/Univers/Univers-Condensed-Bold.ttf) format('woff');
}
@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url(./fonts/Calibri/Calibri.ttf) format('woff');
}

@font-face {
  font-family: 'NotoSansArabic';
  src: local('NotoSansArabic'), url(./fonts/NotoSansArabic/NotoSansArabic.ttf) format('woff');
}
@font-face {
  font-family: 'NotoSansArabic Bold';
  src: local('NotoSansArabic'), url(./fonts/NotoSansArabic/NotoSansArabic-Bold.ttf) format('woff');
}
@font-face {
  font-family: 'NotoSansArabic Light';
  src: local('NotoSansArabic'), url(./fonts/NotoSansArabic/NotoSansArabic-Light.ttf) format('woff');
}

// html { font-size: 1rem }

// @media (max-width: 1240px) {
//   html { color: red; font-size: 0.75rem }
// }

h1.ant-typography,
h2.ant-typography,
h3.ant-typography {
  color: $color-primary;
}

h1.ant-typography {
  font-size: 1.5625rem;
}

h2.ant-typography {
  font-size: 1.25rem;
}

h3.ant-typography {
  font-size: 1.125rem;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
label {
  color: $color-primary;
  font-weight: normal;
}
p.ant-typography {
  font-size: 1.125rem;
}

span.ant-typography {
  font-size: 1rem;
}

@media (max-width: $md) {
  h1.ant-typography {
    font-size: 23px;
  }

  h2.ant-typography {
    font-size: 1.125rem;
  }

  h3.ant-typography {
    font-size: 1rem;
  }

  p.ant-typography {
    font-size: 1rem;
  }
  a {
    font-size: 1rem;
  }
}

@media (max-width: $sm) {
  a {
    font-size: 0.875rem;
  }
  h1.ant-typography {
    font-size: 1.25rem;
  }
  span.ant-typography {
    font-size: 0.875rem;
  }
}
