@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.changeEmailWrapper {
  font-family: 'Univers';
  padding-top: 110px;
  padding-right: 403px;
  padding-left: 403px;
  padding-bottom: 250px;
  @media (max-width: $xxl) {
    padding-top: 100px;
    padding-right: 200px;
    padding-left: 200px;
  }
  @media (max-width: $xl) {
    padding-right: 90px;
    padding-left: 90px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
    padding-right: 58px;
    padding-left: 58px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 488px;
  }
  @media (max-width: '390px') {
    padding-bottom: 507px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 530px;
  }

  .formWrapper {
    .form {
      padding: 20px 80px 18px;
      .formInputs {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
      .submitBtn {
        display: flex;
        justify-content: center;
      }
    }
    @media (max-width: $xxl) {
      .form {
        padding: 0 20px 16px;
      }
    }
    @media (max-width: $md) {
      .form {
        padding: 24px 0 4px;
        .formInputs {
          row-gap: 6px;
        }
      }
    }
  }

  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
