@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.projectListWrapper {
  padding-bottom: 280px;
  @media (max-width:$lg) {
    padding-bottom: 510px;
  }
  @media (max-width:$sm) {
    padding-bottom: 478px;
  }
  .projectListCards {
    padding: 70px 58px 0;
  }

  .projectList {
    .projectListHeader {
      background-color: $color-background;
    }
    .projectListCards {
      padding-top: 40px;
      background-color: $color-white;
      @media (max-width: $md) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .projectsPagination {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      @media (max-width: $xs) {
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: $md) {
    margin: -30px -30px 0;
  }
}
