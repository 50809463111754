@import '../../../theme/colors.scss';
@import 'theme/breakpoints.scss';

.contactUsWrapper {
  padding-bottom: 295px;
  @media (max-width: $lg) {
    padding-bottom: 480px;
    font-size: small;
  }
  @media (max-width: $xxs) {
    padding-bottom: 520px;
  }
  .contactUsTitle {
    background-color: $color-background;
  }
  .contactUs {
    padding: 90px 116px 0;
    justify-content: space-between;
    @media (max-width: $xxl) {
      padding: 91px 58px 0;
    }
    @media (max-width: $md) {
      padding: 51px 30px 0;
    }
    display: flex;
    column-gap: 20px;
    background-color: $color-white;
    .contactUsInfo {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 49%;
      img{
        height: 94%;
        object-fit: cover;
      }
     
    }
    .contactUsFormsWrapper {
      width: 48%;
      @media (max-width: $md) {
         width: 100%;
      }
      .contactUsForms {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .alertWrapper {
          margin-top: -33px;
          margin-bottom: 20px;
          @media (max-width: $xxl) {
            padding: 0px;
          }
        }
        .contactUsForm {
          padding: 0px 0px 20px;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          @media (max-width: $xxl) {
            padding: 0px 0 70px;
          }
          @media (max-width: $md) {
            padding: 0 0 35px;
          }
        }
        

        .submitBtn {
          display: flex;
          justify-content: center;
          @media (max-width: $md) {
            margin-top: 0;
          }
        }
      }
    }
  }
  @media (max-width: $lg) {
    .contactUs {
      .contactUsInfo {
        display: none;
      }
      .contactUsFormsWrapper {
        width: 100%;
      }
    }
  }
  @media (max-width: '1024px') {
    .contactUs {
      .contactUsInfo {
        display: none;
      }
      .contactUsFormsWrapper {
        width: 100%;
      }
    }
  }
  @media (max-width: $md) {
    margin: -30px;
    .contactUsTitle {
      padding: 30px 0 0;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
