@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.checkboxWrapper {
  font-family: 'Univers';
  font-size: 20px !important;
  font-weight: normal !important;
  color: $color-primary !important;
  @media (max-width: $xxl) {
    font-size: 18px !important;
  }
  @media (max-width: $sm) {
    font-size: 16px !important;
  }
}
