@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.contentWrapper {
  .contentHeader {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    color: $color-primary;

    @media (max-width: $md) {
      font-size: 1rem;
    }
  }

  .contentCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 45px;
    border-radius: 10px;
    background-color: $color-white;

    .contentCardImage {
      width: 128px;
      height: 128px;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
    }

    .contentCardName {
      font-family: 'Univers';
      font-size: 1.375rem;
      font-weight: bold;
      color: $color-primary;
      margin-top: 10px;
      margin-bottom: 0px;

      @media (max-width: $md) {
        font-size: 1.0625rem;
      }
    }

    .contentCardCommunity {
      font-size: 1rem;
      color: #76cef2;
      margin-top: 3px;
      margin-bottom: 0px;
    }

    .contentCardData {
      margin-top: 30px;
      margin-bottom: 0px;

      width: 100%;

      .contentCardDataRow {
        margin-top: 30px;
        margin-bottom: 0px;
        font-size: 1.125rem;

        @media (max-width: $lg) {
          font-size: 0.875rem;
        }

        .contentCardDataKey {
          letter-spacing: -0.9px;
          color: $color-secondary-500;
          padding-left: 170px;

          @media (max-width: $xxl) {
            padding-left: 71px;
          }

          @media (max-width: $xl) {
            padding-left: 21px;
          }

          @media (max-width: $lg) {
            padding-left: 0px;
          }

          &:lang(ar) {
            padding-right: 170px;
            padding-left: 0px;

            @media (max-width: $xxl) {
              padding-right: 100px;
            }

            @media (max-width: $xl) {
              padding-right: 50px;
            }

            @media (max-width: $lg) {
              padding-right: 0px;
            }
          }
        }

        .contentCardDataValue {
          line-height: 1.67;
          color: $color-primary;
          padding-left: 21px;
          word-break: break-word;

          &:lang(ar) {
            padding-left: 0px;
            padding-right: 50px;
          }
        }
      }
    }
  }
}

.english {
  font-family: 'Univers';
}

.ltr {
  font-family: 'Univers';
  direction: ltr;
  &:lang(ar) {
    text-align: end;
  }
}
