@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.signup {
  padding: 80px 390px 273px;

  @media (max-width: $xxl) {
    padding-right: 320px;
    padding-left: 320px;
  }
  @media (max-width: "1400px") {
    padding-right: 250px;
    padding-left: 250px;
  }
  @media (max-width: $xl) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media (max-width: $lg) {
    padding-bottom: 510px;
  }
  @media (max-width: $md) {
    padding-top: 5px;
    padding-bottom: 430px;
    padding-right: 0px;
    padding-left: 0px;
  }
  @media (max-width: '375px') {
    padding-bottom: 475px;
  }
  .forCardBottom {
    padding-bottom: 55px;
  }
}
