@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.sponsorshipsWrapper {
    margin-top: 33px;

    @media (max-width: $lg) {
        margin-top: 40px;
    }

    .mySponsorshipTitle {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.5;
        color: $color-primary;
        display: flex;
        justify-content: space-between;

        p {
            margin-bottom: 0px;
        }

        .mySponsorshipTitleView {
            font-weight: normal;
            padding: 0px 10px;
            cursor: pointer;

            img {
                width: 26px;
                margin-left: 9px;
                
                &:lang(ar) {
                    transform: rotate(180deg);
                    margin-left: 0px;
                    margin-right: 9px;
                }
            }
        }

        @media (max-width: $md) {
            font-size: 1rem;
            text-align: center;
            line-height: 2.5;

            .mySponsorshipTitleView {
                font-size: 0.875rem;
            }
        }

        &:lang(ar) {
            text-align: right;
        }
    }

    .sponsorshipsTitle {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: normal;
        color: $color-primary;

        @media (max-width: 1400px) {
            font-size: 1.875rem;
        }

        @media (max-width: $lg) {
            font-size: 1.375rem;
        }

        @media (max-width: $md) {
            font-size: 1.25rem;
            text-align: center;
            line-height: 2.5;
            padding-top: 30px;
        }
    }

    .childImage {
        width: 48px;
        height: 66px;
        object-fit: cover;
        aspect-ratio: 1 / 1;
    }

    .childIdName {
        font-size: 1.125rem;
        font-weight: bold;
        font-stretch: normal;
        color: $color-primary;
        direction: ltr;
        display: flex;
        align-items: flex-start;

        @media (max-width: $md) {
            font-size: 0.875rem;
        }

        span {
            font-family: 'Univers';
            color: $color-secondary-500;
            margin: 0px 12px;
        }
    }

    .childPrice {
        font-size: 1rem;
        font-weight: normal;

        @media (max-width: $md) {
            font-size: 0.75rem;
        }

        span {
            font-size: 1.125rem;
            font-weight: bold;

            @media (max-width: $md) {
                font-size: 0.875rem;
            }
        }
    }

    .date {
        font-weight: normal;
    }

    .actions {
        display: flex;
        justify-content: space-around;
        align-items: center;

        div {
            cursor: pointer;
        }
    }

    &:lang(ar) {
        font-family: 'NotoSansArabic';
    }
}