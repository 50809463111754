@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.headerContainer {
  z-index: 5;
  font-family: 'Univers';

  .header {
    margin-right: auto;
    margin-left: auto;

    margin-top: 20px;

    padding-right: 116px;
    padding-left: 116px;

    @media (max-width: $xxl) {
      padding-right: 58px;
      padding-left: 58px;
    }

    @media (max-width: $md) {
      padding-right: 29px;
      padding-left: 29px;
    }

    .headerInfo {
      display: flex;
      justify-content: space-between;
      padding: 0 25px;

      .headerInfoLeft {
        display: flex;
        justify-content: center;
        column-gap: 12px;

        .headerInfoLeftImage {
          display: flex;

          img {
            width: 20px;
          }
        }

        .headerInfoLeftEmail {
          display: flex;
          align-items: center;

          a {
            font-size: 1rem;
            margin: 0px;
            font-family: 'Univers';
          }
        }

        .headerInfoLeftEmailWhite {
          a {
            color: $color-white;
          }
        }
      }

      .headerInfoRight {
        display: flex;
        column-gap: 45px;
        direction: ltr;

        @media (max-width: $md) {
          margin: 15px 36px;
        }

        @media (max-width: $xs) {
          margin: 35px 36px;
        }
      }
    }

    .headerContent {
      display: flex;
      justify-content: space-between;
      padding: 26px 55px;
      margin-top: 5px;
      border-radius: 20px;
      border: solid 0.5px #b0b8be;
      background-color: $color-white;
      align-items: center;

      @media (max-width: '1470px') {
        padding: 18px 23px;
      }

      .headerContentLinks {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: '1470px') {
          font-size: 1rem;
        }

        ul {
          border: none;
          justify-content: space-evenly;
          width: 100%;

          &::before {
            content: none !important;
          }

          li {
            span {
              a {
                color: $color-primary;
                font-size: 1.25rem;
                text-transform: uppercase;

                &:lang(ar) {
                  font-size: 1.5625rem;
                }

                @media (max-width: '1470px') {
                  font-size: 1.0625rem;

                  &:lang(ar) {
                    font-size: 1.125rem;
                  }
                }
                @media (max-width: '1260px') {
                  font-size: 1.0625rem;

                  &:lang(ar) {
                    font-size: 1.125rem;
                  }
                }
              }
            }

            background-color: transparent;
          }
        }
      }

      .headerContentButtons {
        column-gap: 20px;
        display: flex;
        align-items: center;
      }

      .headerContentButtonsMobile {
        width: 100%;
        justify-content: end;
        margin-left: 20px;
        margin-right: 20px;
      }

      .headerDrawer {
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 25px;
        min-width: 30px;
        align-items: center;

        div {
          width: 30px;
          height: 3px;
          border-radius: 25px;
          background: $color-offwhite;
        }

        img {
          width: 15px;
        }
      }
    }

    .subHeaderContent {
      position: absolute;
      top: 0;
      right: 116px;
      z-index: -1;
      margin-top: 160px;
      padding: 25px 0px 10px;
      background-color: rgba($color: $color-secondary-500, $alpha: 0.1);
      border-radius: 10px;

      .subHeaderContentLinks {
        display: flex;
        align-items: center;
        justify-content: end;
        column-gap: 75px;
        padding: 0px 83px;

        @media (max-width: '1750px') {
          column-gap: 60px;
          padding: 0px 63px;
        }

        a {
          font-size: 1rem;
          color: #4f4f53;
        }

        .subHeaderActive {
          a {
            color: $color-primary !important;
            font-weight: bold;
          }
        }
        &:lang(ar) {
          column-gap: 110px;
          padding: 0px 100px;

          @media (max-width: '1750px') {
            column-gap: 90px;
            padding: 0px 90px;
          }
          @media (max-width: '1470px') {
            column-gap: 70px;
            padding: 0px 80px;
          }
        }
      }

      &:lang(ar) {
        left: 116px;
        right: auto;
      }

      @media (max-width: $xxl) {
        right: 58px;
        &:lang(ar) {
          left: 58px;
          right: auto;
        }
      }

      @media (max-width: '1470px') {
        margin-top: 136px;
        padding: 37px 0px 16px;

        .subHeaderContentLinks {
          column-gap: 40px;
          padding: 0px 30px;
        }
      }
    }

    .headerContentMobile {
      max-height: 100px;
      transition: max-height 0.5s ease-out;
      border-radius: 10px;
      padding: 20px 22px;
      margin-top: 5px;
      display: block;

      .headerContentMobileTop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .headerContentLogo {
          height: 40px;

          a {
            img {
              height: 100%;
            }
          }
        }
      }

      .headerContentButtonsMobileNav {
        margin-top: 80px;
        justify-content: center;
        align-items: center;
      }

      .headerContentButtonsMobileNavBottom {
        @media (max-width: $md) {
          flex-direction: column;
          gap: 25px;
        }
      }

      .subHeaderOpener {
        margin: 40px -22px 0px;

        .subHeaderOpenerAccount {
          cursor: pointer;
          padding: 22px 0px;
          position: relative;
          background-color: $color-background;

          .subHeaderOpenerAccountContent {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;

            span {
              margin-bottom: -10px;
              font-size: 1.25rem;
            }
          }

          .subHeaderOpenerAccountArrow {
            position: absolute;
            width: 7px;
            right: 90px;
            top: 45%;

            @media (max-width: $xs) {
              right: 45px;
            }

            &:lang(ar) {
              transform: rotate(180deg);
              right: inherit;
              left: 90px;

              @media (max-width: $xs) {
                right: inherit;
                left: 45px;
              }
            }
          }
        }

        .subHeaderOpenerBack {
          cursor: pointer;
          margin: 0px 30px;
          img {
            width: 32px;

            &:lang(ar) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .linkListContainer {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 100%;
        transition: all 0.5s ease;
        right: 0px;

        &:lang(ar) {
          right: inherit;
          left: 0px;
        }

        .linkListContainerMenu {
          width: 100%;
        }

        .linkListContainerMenuSecond {
          .headerContentLinks {
            ul {
              margin-top: 0px;
            }
          }
        }

        .headerContentLinks {
          ul {
            margin-top: 40px;
            gap: 35px;
            display: flex;
            flex-direction: column;

            li {
              display: flex;
              justify-content: center;
              margin-bottom: -8px;
            }
          }
        }
      }

      .linkListContainerMulti {
        width: 200% !important;
      }

      .linkListContainerOpen {
        right: 100% !important;

        &:lang(ar) {
          right: inherit !important;
          left: 100% !important;
        }
      }

      .headerInfo {
        display: none;
        margin-top: 30px;
        flex-direction: column;
        align-items: center;

        @media (max-width: $xs) {
          margin-top: 80px;
        }
      }
    }

    .headerContentMobileOpen {
      max-height: 780px;
      height: 600px;
      overflow: hidden;
      transition: max-height 0.5s ease-out;

      @media (max-width: $md) {
        max-height: 780px;
        height: 780px;
      }
    }
  }
}

html:lang(ar) {
  .headerContainer {
    font-family: 'NotoSansArabic';
  }
}
