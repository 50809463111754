@import '../../../theme/colors.scss';
@import 'theme/breakpoints.scss';

.donationWrapper {
  padding-bottom: 295px;
  @media (max-width: $lg) {
    padding-bottom: 480px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 520px;
  }
  .donationTitle {
    background-color: $color-background;
  }
  .donation {
    padding: 40px 116px 0;
    @media (max-width: $xxl) {
      padding: 91px 58px 0;
    }
    @media (max-width: $md) {
      padding: 51px 30px 0;
    }
    display: flex;
    column-gap: 40px;
    background-color: $color-white;
    .donationInfo {
      display: flex;
      flex-direction: column;
      width: 32%;
      background-color: $color-background;
      row-gap: 55px;

      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      .donationInfoText {
        display: flex;
        flex-direction: column;
        row-gap: 45px;
        padding: 0 25px;
        color: $color-primary;
        padding: 0 60px;
        height: 1100px;
        .donationIcon {
          img {
            @media (max-width: $xxl) {
              width: 40%;
            }
          }
        }

        .donationText1 {
          font-family: 'Univers';
          font-size: 3.125rem;
          font-weight: bold;

          p {
            margin-bottom: 0;
          }
        }
        .donationText2 {
          font-family: 'Calibri';
          font-size: 2.1875rem;
          font-weight: normal;
          span {
            font-weight: bold;
          }
        }
        @media (max-width: $xxl) {
          height: 1000px;
          padding: 0 50px;
          .donationText1 {
            font-size: 2.5rem;
          }
          .donationText2 {
            font-size: 1.75rem;
          }
        }
      }
    }
    .donationFormsWrapper {
      width: 68%;
      .donationForms {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        .alertWrapper {
          padding: 0px 60px;
          @media (max-width: $xxl) {
            padding: 0px;
          }
        }
        .donationForm {
          padding: 50px 60px 70px;
          display: flex;
          flex-direction: column;
          @media (max-width: $xxl) {
            padding: 50px 0 70px;
          }
          @media (max-width: $md) {
            padding: 30px 0 35px;
          }
          .transactionTotalAmount {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 40px;
            @media (max-width: $xxl) {
              column-gap: 20px;
            }
            .transactionFees {
              width: 40%;
            }
            .totalAmount {
              width: 60%;
            }
            .transactionFees,
            .totalAmount {
              label {
                font-family: 'Univers';
                font-size: 1.25rem;
                font-weight: bold;
                color: $color-primary;
                @media (max-width: $xxl) {
                  font-size: 1.125rem;
                }
                @media (max-width: $sm) {
                  font-size: 1rem;
                }
              }
              .totalAmountValue,
              .transactionFeesValue {
                margin-top: 8px;
                display: flex;
                align-items: center;
                border-radius: 10px;
                border: solid 1px $color-secondary-500;
                background-color: $color-white;
                padding: 15px 0;
                position: relative;
                cursor: not-allowed;
                background-color: $color-light-gray;

                span {
                  text-align: left;
                  color: $color-primary;
                  font-size: 1.25rem;
                  padding: 0 12px 0 25px;
                  @media (max-width: $sm) {
                    font-size: 1rem;
                  }
                  @media (max-width: '320px') {
                    padding: 0 12px 0 8px;
                  }
                }
                P {
                  margin-bottom: 0;
                  font-weight: bold;
                  font-size: 1.875rem;
                  @media (max-width: $xxl) {
                    font-size: 1.75rem;
                  }
                  @media (max-width: $sm) {
                    font-size: 1.125rem;
                  }
                }
                .currencyWrapper {
                  background-color: $color-primary;
                  position: absolute;
                  right: 0;
                  height: 79px;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 5%;
                  @media (max-width: 1240px) {
                    height: 67px;
                  }
                  @media (max-width: $sm) {
                    height: 54px;
                  }
                  p {
                    margin-bottom: 0;
                    font-size: 1.25rem;
                    font-weight: normal;
                    color: $color-white;
                    @media (max-width: $sm) {
                      font-size: 1rem;
                    }
                  }
                  &:lang(ar) {
                    right: auto;
                    left: 0;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 0;
                  }
                }
              }
              .totalAmountValue {
                p {
                  color: $color-primary-500;
                }
              }
            }
          }

          @media (max-width: $md) {
            .transactionTotalAmount {
              flex-direction: column;
              align-items: initial;
              row-gap: 25px;
              .transactionFees,
              .totalAmount {
                width: 100%;
              }
            }
          }
          .amountWrapper {
            position: relative;

            .currency {
              position: absolute;
              top: 62px;
              left: 40px;
              z-index: 9;
              font-family: 'Univers';
              font-size: 1.25rem;
              font-weight: normal;
              font-style: normal;
              color: $color-primary;
              p {
                margin-bottom: 0;
              }
              @media (max-width: $xl) {
                top: 60px;
              }
              @media (max-width: $md) {
                left: 25px;
              }
              @media (max-width: $sm) {
                font-size: 1rem;
                top: 52px;
              }
              &:lang(ar) {
                left: auto;
                right: 40px;
                top: 71px;
                @media (max-width: $sm) {
                  top: 62px;
                }
                @media (max-width: $md) {
                  right: 25px;
                }
              }
            }
            .currencyWrapper {
              background-color: $color-primary;
              position: absolute;
              right: 0;
              top: 35px;
              height: 83px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 5%;
              @media (max-width: '1500px') {
                top: 34px;
                height: 81px;
              }
              @media (max-width: '1240px') {
                height: 72px;
              }
              @media (max-width: $md) {
                top: 33px;
              }
              @media (max-width: $sm) {
                top: 31px;
                height: 62px;
              }
              p {
                margin-bottom: 0;
                font-size: 1.25rem;
                font-weight: normal;
                color: $color-white;
                @media (max-width: $sm) {
                  font-size: 1rem;
                }
              }
              &:lang(ar) {
                right: auto;
                left: 0;
                top: 45px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 0;
                @media (max-width: $sm) {
                  top: 41px;
                }
              }
            }
          }
        }
        .personalForm {
          padding: 50px 60px 27px;
          display: flex;
          flex-direction: column;
          @media (max-width: $xxl) {
            padding: 50px 0 60px;
          }
          @media (max-width: $md) {
            padding: 25px 0 30px;
          }
        }

        .submitBtn {
          display: flex;
          justify-content: center;
          margin-top: 34px;
          @media (max-width: $md) {
            margin-top: 0;
          }
        }
      }
    }
  }
  @media (max-width: $lg) {
    .donation {
      .donationInfo {
        display: none;
      }
      .donationFormsWrapper {
        width: 100%;
      }
    }
  }
  @media (max-width: $md) {
    margin: -30px;
    .donationTitle {
      padding: 30px 0 0;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
