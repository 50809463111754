@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.dollarSymbolDir {
  direction: rtl;
}

.priceBold {
  font-weight: bold;
  font-size: 1.125rem;
  @media (max-width: $md) {
    font-size: 0.875rem;
  }
}
