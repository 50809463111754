@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.sponsoredSingleChildWrapper {
  padding-left: 116px;
  padding-right: 116px;
  padding-bottom: 270px;
  margin-top: 110px;
  @media (max-width: $xl) {
    margin-top: 0;
  }
  .sponsoredSingleChildTitle {
    display: flex;
    align-items: center;
    column-gap: 35px;
    margin-top: 58px;
    .backIcon {
      cursor: pointer;
      img {
        width: 65px;
        height: 31px;
        @media (max-width: $md) {
          width: 35px;
          height: 18px;
        }
        @media (max-width: $xs) {
          width: 25px;
          height: 15px;
        }
        @media (max-width: $xxs) {
          width: 23px;
          height: 12px;
        }
        &:lang(ar) {
          transform: rotateY(180deg);
        }
      }
    }
    p {
      font-size: 2.5rem;
      font-weight: bold;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: $color-primary;
      margin-bottom: 0;
    }
  }
  .sponsoredSingleChildDetailsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 106px;
    padding-top: 86px;
    .sponsorship {
      display: flex;
      column-gap: 40px;
      @media (max-width: $lg) {
        column-gap: 0;
      }
    }
    @media (max-width: $xxl) {
      padding-left: 0;
      padding-right: 0;
    }

    &:lang(ar) {
      padding-left: 0;
      padding-right: 106px;
      @media (max-width: $xxl) {
        padding-right: 0;
      }
    }
    .sponsoredSingleChildImage {
      display: flex;
      height: 511px;
      max-width: 400px;
      align-items: start;
      overflow: hidden;
      border-radius: 20px;
      position: relative;
      

      .childBadge {
        background-color: $color-secondary;
        position: absolute;
        transform: rotate(315deg);
        top: 34px;
        padding: 0px 77px;
        color: $color-white;
        left: -78px;
        font-size: 1.25rem;
        
        &:lang(ar) {
          transform: rotate(45deg);
          left: auto;
          right: -63px;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
      @media (max-width: $lg) {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
      @media (max-width: $md) {
        height: 384px;
        max-width: 100%;
      }
    }
    .childCodeName {
      display: flex;
      column-gap: 10px;
      font-size: 2.5rem;
      font-weight: bold;
      color: $color-primary;
      .childCode {
        color: $color-secondary-500;
      }
      @media (max-width: $xxl) {
        font-size: 1.5625rem;
      }

      @media (max-width: $lg) {
        display: none;
      }
    }
    .sponsoredSingleChildDetails {
      .sponsoredChildDetails {
        margin-top: 84px;
        .childDetails {
          margin-top: 24px;
        }
      }

      @media (max-width: $lg) {
        .sponsoredChildDetails {
          margin-top: 35px;
          .childDetails {
            margin-top: 24px;
          }
        }
      }
    }
    .sponsorshipDueWrapper {
      margin-top: 60px;
      display: flex;
      align-items: center;
      column-gap: 30px;
      span {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 2.5;
        letter-spacing: normal;
        color: $color-primary-500;
      }
      @media (max-width: $xl) {
        column-gap: 6px;
      }
      @media (max-width: $lg) {
        margin-top: 40px;
        column-gap: 10px;
      }
      @media (max-width: $md) {
        span {
          font-size: 1rem;
        }
      }
    }

    .sponsoredSingleChildBtnsWrapper {
      .childCodeName {
        display: none;
        @media (max-width: $lg) {
          display: flex;
          margin: 30px 0;
        }
        @media (max-width: $md) {
          font-size: 1.25rem;
        }
      }
      .sponsoredSingleChildBtns {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        @media (max-width: $md) {
          row-gap: 10px;
        }
      }
    }
  }
  .sponsoredSingleChildInfo {
    display: flex;
    flex-direction: column;
    row-gap: 73px;
    margin-top: 65px;
    padding-left: 83px;
    padding-right: 83px;
    @media (max-width: $lg) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (max-width: $md) {
      row-gap: 51px;
    }
    .childInfo {
      .childInfoYear {
        font-family: 'Univers';
        position: relative;
        font-size: 1.25rem;
        font-weight: bold;
        color: $color-primary;
        line-height: 1.5;
        letter-spacing: normal;
        .qouteIcon {
          position: absolute;
          left: -30px;
          top: -25px;
          @media (max-width: $lg) {
            left: 0;
          }
          img {
            transform: rotateY(180deg);
            width: 50px;
          }

          &:lang(ar) {
            img {
              transform: rotateY(360deg);
            }
            left: auto;
            right: -30px;
            @media (max-width: $lg) {
              right: 0;
            }
          }
        }
        @media (max-width: $lg) {
          padding: 0 30px;
        }
      }
      .childInfoData {
        margin-top: 7px;
      }
    }
    .childAdditionalInfo {
      .childInfoData {
        margin-top: 13px;
      }
    }
    .singleChildVillageInfo {
      .childInfoData {
        margin-top: 10px;
        width: 75%;
        @media (max-width: $lg) {
          width: 100%;
          line-height: 1.67;
        }
      }
    }
    .childInfoData {
      width: 80%;
      font-family: 'Calibri';
      font-size: 1.3125rem;
      font-weight: normal;
      color: $color-primary;
      line-height: 1.43;
      letter-spacing: normal;
      @media (max-width: $lg) {
        width: 100%;
      }
    }
  }
  .paymentHistoryWrapper {
    margin-top: 40px;
    .paymentHistoryTitle {
      font-size: 2.5rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: $color-primary;
    }
    .paymentHistoryData {
      margin: 28px 470px 0;
      @media (max-width: $xxl) {
        margin: 28px 210px 0;
      }
      @media (max-width: $xxl) {
        margin: 28px 150px 0;
      }
      @media (max-width: $lg) {
        margin: 28px 38px 0;
      }
      @media (max-width: $md) {
        margin: 10px 0 0;
      }
      .sponsorshipPayDate {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0 62px;
        column-gap: 10px;
        text-transform: uppercase;
        color: $color-black;
        line-height: 1.88;
        letter-spacing: 1.6px;
        font-weight: bold;
        font-size: 1rem;
        @media (max-width: $md) {
          line-height: 4.17;
          letter-spacing: 0.6px;
          font-size: 0.75rem;
          padding: 0;
          padding-left: 20px;
        }
        &:lang(ar) {
          letter-spacing: normal;
          @media (max-width: $md) {
            padding-left: 0;
            padding-right: 20px;
          }
        }
      }
      .sponsorshipPrice {
        display: flex;
        justify-content: flex-start;
        font-size: 1.125rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-primary-500;
        display: flex;
        @media (max-width: $md) {
          line-height: 3.13;
          font-size: 1rem;
        }
      }
    }
    .paymentHistoryPagination {
      display: flex;
      justify-content: center;
      margin-top: 56px;
      margin-bottom: 10px;
      @media (max-width: $md) {
        margin-top: 10px;
      }
    }
    @media (max-width: $md) {
      margin-top: 50px;
      .paymentHistoryTitle {
        font-size: 1.25rem;
        line-height: 2.5;
        text-align: left;
      }
    }
  }
  .childDetails {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin-top: 10px;
    .childDetail {
      display: flex;
      column-gap: 17px;
      font-family: 'Univers';
      font-size: 1.125rem;
      line-height: normal;
      letter-spacing: normal;
      color: $color-primary;
      .frequency {
        font-size: 1rem;
        padding: 0 4px;
      }
      .sponsorshipDetailsLabel {
        color: $color-secondary-500;
        min-width: 182px;
        letter-spacing: -0.9px;
        @media (max-width: $xs) {
          min-width: 160px;
        }
      }
    }
  }
  .singleChildRemember {
    font-family: 'Univers';
    position: relative;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-primary;
    padding: 44px 420px 0;
    @media (max-width: $xxl) {
      padding: 44px 160px 0;
    }
    @media (max-width: $lg) {
      padding: 44px 100px 0;
    }
    @media (max-width: $md) {
      padding: 44px 20px 0;
      font-size: 1.1875rem;
    }
    @media (max-width: '375px') {
      padding: 44px 0 0;
    }
    .qouteIcon {
      position: absolute;
      right: 400px;
      top: 5px;
      @media (max-width: $xxl) {
        right: 120px;
        top: 2px;
      }
      @media (max-width: '1400px') {
        right: 145px;
        top: 5px;
      }
      @media (max-width: $xl) {
        right: 125px;
      }
      @media (max-width: $lg) {
        right: 70px;
      }
      @media (max-width: $md) {
        right: -5px;
        top: 10px;
        img {
          width: 50px;
        }
      }
      @media (max-width: '375px') {
        right: -15px;
      }
      &:lang(ar) {
        img {
          transform: rotateY(180deg);
        }
        right: auto;
        left: 400px;
        top: 20px;
        @media (max-width: $xxl) {
          left: 145px;
        }
        @media (max-width: $xl) {
          left: 125px;
        }
        @media (max-width: $lg) {
          left: 70px;
        }
        @media (max-width: $md) {
          left: -5px;
          top: 25px;
          img {
            width: 50px;
          }
        }
        @media (max-width: '375px') {
          left: -15px;
        }
      }
    }
  }
  .childTitle {
    font-size: 1rem;
    font-weight: bold;
    color: $color-black;
    text-transform: uppercase;
    font-family: 'Univers';
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    column-gap: 13px;
    p {
      margin-top: 5px;
      margin-bottom: 0;
    }
    &:lang(ar) {
      letter-spacing: normal;
    }
  }
  @media (max-width: $xxl) {
    padding-left: 58px;
    padding-right: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 500px;
    .sponsoredSingleChildTitle {
      margin-top: 44px;
    }
    .sponsoredSingleChildDetailsWrapper {
      flex-direction: column;
      padding-top: 30px;
      .sponsoredSingleChildDetails {
        order: 3;
      }
      .sponsoredSingleChildBtns {
        order: 2;
        align-items: center;
      }
    }
  }
  @media (max-width: $md) {
    padding-left: 29px;
    padding-right: 29px;
    margin: -30px;
    padding-bottom: 490px;

    .sponsoredSingleChildInfo {
      .childInfo {
        .childInfoYear {
          font-size: 1rem;
          line-height: 1.88;
        }
      }
    }
    .childDetails {
      row-gap: 20px;
      margin-top: 15px;
      .childDetail {
        column-gap: 12px;
        font-size: 0.875rem;
        .sponsorshipDetailsLabel {
          letter-spacing: -0.7px;
        }
        .frequency {
          font-size: 0.75rem;
        }
      }
    }
    .sponsoredSingleChildInfo {
      .childInfoData {
        font-size: 1.125rem;
        line-height: 1.39;
      }
    }
    .childTitle {
      font-size: 0.75rem;
      letter-spacing: 1.2px;
    }
  }
  @media (max-width: $sm) {
    .sponsoredSingleChildTitle {
      p {
        font-size: 1.25rem;
        line-height: 2.5;
      }
    }
  }
  @media (max-width: '390px') {
    padding-bottom: 510px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 540px;
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}

.cancelSponsorshipModalTitle {
  font-family: 'Univers';
  font-size: 2.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $color-primary;
  margin-top: 50px;
  p {
    margin-bottom: 54px;
  }
  @media (max-width: $xxl) {
    font-size: 2.0625rem;
    margin-top: 0;
    p {
      margin-bottom: 30px;
    }
  }
  @media (max-width: $md) {
    font-size: 1.25rem;
    line-height: normal;
    margin-top: 30px;
    p {
      margin-bottom: 35px;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}

.cancelSponsorshipModalBtn {
  display: flex;
  justify-content: center;
  margin-top: 53px;
  margin-bottom: 30px;
  @media (max-width: $md) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
