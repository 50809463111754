@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.resetPasswordWrapper {
  display: flex;
  font-family: 'Univers';
  padding-top: 81px;
  padding-right: 116px;
  padding-left: 116px;
  padding-bottom: 267px;
  column-gap: 41px;
  @media (max-width: $xxl) {
    padding-top: 51px;
    padding-right: 58px;
    padding-left: 58px;
  }
  @media (max-width: $lg) {
    padding-bottom: 447px;
  }

  @media (max-width: $md) {
    margin: -30px;
    padding-top: 34px;
    padding-right: 29px;
    padding-left: 29px;
  }
  @media (max-width: '632px') {
    padding-bottom: 472px;
  }
  @media (max-width: $sm) {
    padding-bottom: 458px;
  }
  @media (max-width: '384px') {
    padding-bottom: 497px;
  }
  @media (max-width: $xxs) {
    padding-bottom: 510px;
  }
  .resetPasswordImage {
    width: 32%;
    background-image: url('/assets/images/forget-password-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: solid 1px $color-secondary-500;
    border-radius: 20px;
  }
  .resetPasswordForm {
    width: 68%;
    .formWrapper {
      .form {
        padding: 49px 90px 16px;
        .formInputs {
          display: flex;
          flex-direction: column;
          row-gap: 36px;
        }
        .error {
          color: $color-error;
          margin-top: -40px;
          margin-bottom: 60px;
        }
        .sendBtn {
          display: flex;
          justify-content: center;
        }
      }
      @media (max-width: $xxl) {
        .form {
          padding: 49px 30px 16px;
        }
      }
      @media (max-width: $md) {
        .form {
          padding: 24px 0 4px;
          .formInputs {
            row-gap: 8px;
          }
        }
      }
      @media (max-width: $sm) {
        .form {
          .resetPasswordText {
            padding: 0;
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: $lg) {
    .resetPasswordImage {
      display: none;
    }
    .resetPasswordForm {
      width: 100%;
    }
  }
  &:lang(ar) {
    font-family: 'NotoSansArabic';
  }
}
