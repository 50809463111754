@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.childrenListWrapper {
  padding: 80px 66px 100px;
  background-color: $color-white;
  @media (max-width: $xxl) {
    padding: 30px 28px;
  }
  @media (max-width: $lg) {
    padding: 30px 18px;
  }
  @media (max-width: $md) {
    margin-right: -32px;
    margin-left: -32px;
    padding: 30px 0px;
  }

  .childrenList {
    .childrenListCards {
      .childCard{

        .childrenListSubTitle {
          display: flex;
          justify-content: space-between;
  
          .childrenListSubTitleCode {
            font-family: 'Univers';
          }
        }

        padding: 0 20px;
        @media (max-width:$xxl) {
          padding: 0 10px;
        }
      }

    }

    .childrenListExplore {
      display: flex;
      justify-content: center;
      margin-top: 90px;
      @media (max-width: $xl) {
        margin-top: 40px;
      }
      @media (max-width: $md) {
        margin-top: 33px;
      }
    }
  }
}
